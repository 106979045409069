import { fetchAPI, fetchFile } from './apiUtils';
import { GROUP_ORDER_URL } from './presetApi';

export const CATERING_ORDER_URL = 'api/v2/catering_orders';

export function createCateringOrder(cart, eft = false) {
  const formData = {
    cart,
    eft,
  };
  return fetchAPI(CATERING_ORDER_URL, 'POST', formData);
}
export function createLastMinuteAddItem(id, data) {
  return fetchAPI(`${CATERING_ORDER_URL}/${id}/addon_orders`, 'GET', data);
}
export function createLastMinuteOrder(id) {
  return fetchAPI(`${CATERING_ORDER_URL}/${id}/add_on_order`, 'POST');
}
export function createAddLastMinuteItemPayment(cart, id, eft = false) {
  const formData = {
    cart,
    eft,
  };

  return fetchAPI(
    `${CATERING_ORDER_URL}/${id}/update_addon_order`,
    'PUT',
    formData,
  );
}

export function suggestions(id, data) {
  return fetchAPI(`${CATERING_ORDER_URL}_suggestions/${id}`, 'POST', data);
}

export function getCateringOrder(id) {
  return fetchAPI(`${CATERING_ORDER_URL}/${id}`, 'GET');
}

export function getGroupOrders(id) {
  return fetchAPI(`${GROUP_ORDER_URL}/${id}/detail`, 'GET');
}

export function updateCateringOrder(cart, id, isEft) {
  const formData = {
    cart,
    isEft,
  };
  return fetchAPI(`${CATERING_ORDER_URL}/${id}`, 'PUT', formData);
}

export function finalizeCateringOrder(paymentMethodId, id, saveCard) {
  const formData = {
    status: 'customer_paid',
    payment_method_id: paymentMethodId,
    save_card: saveCard,
  };
  return fetchAPI(`${CATERING_ORDER_URL}/${id}/complete`, 'PUT', formData);
}

export function cancelCateringOrder(id) {
  return fetchAPI(`${CATERING_ORDER_URL}/${id}`, 'DELETE');
}

export function cancelGroupOrders(id) {
  return fetchAPI(`${GROUP_ORDER_URL}/${id}`, 'DELETE');
}

export function getCateringOrderPdf(id) {
  return fetchFile(
    `api/v2/catering_order_invoices/${id}.pdf`,
    `order_${id}.pdf`,
    'GET',
  );
}

export function getGroupOrderPdf(id) {
  return fetchFile(
    `api/v2/group_order_invoices/${id}.pdf`,
    `order_${id}.pdf`,
    'GET',
  );
}
