/* eslint-disable no-nested-ternary */
import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { priceDecimalFormatter } from 'formatter/preset.formatter';
import './index.scss';
import { format } from 'date-fns';
import classNames from 'classnames';
import LoadingAnimation from '../LoadingAnimation';
import { store } from 'react-notifications-component';

const filterRepeat = arr =>
  arr
    .filter(
      (item, index) =>
        arr.map(i => i.parentId).indexOf(item.parentId) === index,
    )
    .map(i => i.id);

const CardItem = ({
  orderItem,
  cateringOrderInfo,
  groupOrderInfo,
  setCancelOrderId,
  upcoming,
  getStatus,
  getCateringOrderPdf,
  getGroupOrderPdf,
  defaultView,
  createLastMinuteAddItem,
}) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const Loader = useMemo(() => {
    if (isLoading) {
      return <LoadingAnimation />;
    }
    return null;
  }, [isLoading]);
  const getSubItems = items => {
    const idsMenuName = filterRepeat(items);
    return items.map(item => {
      return (
        <>
          {idsMenuName.includes(item.id) && item.modelType === 'MenuItem' && (
            <div className="order-table-info-bold">{item?.menuName}</div>
          )}
          <div
            key={item.id}
            className={classNames('order-table-sub-info', {
              'order-table-info-bold': item.modelType !== 'MenuItem',
            })}
          >
            <span className="red-amount">{item?.amount}</span>{' '}
            {item?.name || 'No name'} ($
            {priceDecimalFormatter(item?.subTotal)})
          </div>
        </>
      );
    });
  };
  const handleCancelOrder = order => {
    setCancelOrderId(order.id);
  };
  const dateValid =
    new Date(orderItem?.deliveryDateTime) > new Date() &&
    new Date(orderItem?.orderProcessingAt) > new Date();
  const addExtraItem = cateringOrderInfo?.isAddonOrdersEnabled;

  return (
    <>
      <div className="container-fluid container-orders order-view-content pl-5 pr-5">
        <div className="row no-gutters table-border">
          <div className="col-12 col-md-6 order-table-title">Order Details</div>
          <div className="col-12 col-md-6 d-flex flex-row justify-content-between hidden-sm-down">
            <div className="order-table-title">Payment Details/Status</div>
            <div
              className={
                getStatus(orderItem.status, orderItem.displayStatus).style
              }
            >
              {getStatus(orderItem.status, orderItem.displayStatus).text}
            </div>
          </div>
        </div>
        <div className="row no-gutters table-border order-table">
          <div className="col-12 col-md-6">
            <div className="order-table-category">Vendor Name</div>
            <div className="order-table-info">
              <div className="order-table-info-bold">
                {orderItem && orderItem.presetName}
              </div>
            </div>

            <div className="order-table-category">
              Menu item(s)
              {cateringOrderInfo?.itemsTotal
                ? ` ($${priceDecimalFormatter(cateringOrderInfo?.itemsTotal)})`
                : null}
            </div>
            <div className="order-table-info">
              {cateringOrderInfo?.purchaseItems?.length &&
                getSubItems(cateringOrderInfo?.purchaseItems)}
              {groupOrderInfo?.sharableLink ? (
                <div className="order-table-info-bold">
                  To view the items that have been ordered, click here to view
                  full group order: <br />
                  <a
                    href={groupOrderInfo?.sharableLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {groupOrderInfo?.sharableLink}
                  </a>
                </div>
              ) : null}
            </div>

            {groupOrderInfo?.companyName || cateringOrderInfo?.companyName ? (
              <>
                <div className="order-table-category">Company Name</div>
                <div className="order-table-info">
                  {groupOrderInfo?.companyName ||
                    cateringOrderInfo?.companyName}
                </div>
              </>
            ) : null}
            {groupOrderInfo?.displayDeliveryAddress ||
            cateringOrderInfo?.displayDeliveryAddress ? (
              <>
                <div className="order-table-category">Delivery Address</div>
                <div className="order-table-info">
                  {groupOrderInfo?.displayDeliveryAddress ||
                    cateringOrderInfo?.displayDeliveryAddress}
                </div>
              </>
            ) : null}
            {orderItem?.deliveryDateTime ? (
              <>
                <div className="order-table-category">Delivery Time</div>
                <div className="order-table-info">
                  {format(new Date(orderItem.deliveryDateTime), 'PPP')},
                  {format(new Date(orderItem.deliveryDateTime), 'p')}
                </div>
              </>
            ) : null}
            {groupOrderInfo?.deliveryContactPerson ||
            cateringOrderInfo?.firstName ||
            cateringOrderInfo?.lastName ? (
              <>
                <div className="order-table-category">Recipient Name</div>
                <div className="order-table-info">
                  {groupOrderInfo?.deliveryContactPerson ||
                    `${cateringOrderInfo?.firstName} ${cateringOrderInfo?.lastName}`}
                </div>
              </>
            ) : null}
            {groupOrderInfo?.deliveryPhoneNumber ||
            cateringOrderInfo?.phoneNumber ? (
              <>
                <div className="order-table-category">
                  Customer Phone number
                </div>
                <div className="order-table-info">
                  {groupOrderInfo?.deliveryPhoneNumber ||
                    cateringOrderInfo?.phoneNumber}
                </div>
              </>
            ) : null}
            {groupOrderInfo?.headCount ? (
              <>
                <div className="order-table-category">Order Headcount</div>
                <div className="order-table-info">
                  {groupOrderInfo.headCount}
                </div>
              </>
            ) : null}
            {cateringOrderInfo?.extraInfo?.servingStyle ? (
              <>
                <div className="order-table-category">Serving Style</div>
                <div className="order-table-info">
                  {cateringOrderInfo?.extraInfo?.servingStyle}
                </div>
              </>
            ) : null}
            {cateringOrderInfo?.extraInfo?.numCutlery !== null ? (
              <>
                <div className="order-table-category">Cutlery Requested</div>
                <div className="order-table-info">
                  {cateringOrderInfo?.extraInfo?.numCutlery}
                </div>
              </>
            ) : null}
            {groupOrderInfo?.deliveryInstruction ||
            cateringOrderInfo?.moreDetail ? (
              <>
                <div className="order-table-category">Special Instructions</div>
                <div className="order-table-info">
                  {groupOrderInfo?.deliveryInstruction ||
                    cateringOrderInfo?.moreDetail}
                </div>
              </>
            ) : null}
          </div>
          <div className="col-12 col-md-6">
            {groupOrderInfo?.subTotalCents || cateringOrderInfo?.itemsTotal ? (
              <>
                <div className="order-table-category">
                  Order Item(s) Subtotal:
                </div>
                <div className="order-table-info">
                  $
                  {priceDecimalFormatter(
                    groupOrderInfo?.subTotalCents ||
                      cateringOrderInfo?.itemsTotal,
                  )}
                </div>
              </>
            ) : null}
            {groupOrderInfo?.deliveryFeeCents ||
            cateringOrderInfo?.deliveryFee ? (
              <>
                <div className="order-table-category">Delivery Fee:</div>
                <div className="order-table-info">
                  $
                  {priceDecimalFormatter(
                    groupOrderInfo?.deliveryFeeCents ||
                      cateringOrderInfo?.deliveryFee,
                  )}
                </div>
              </>
            ) : null}
            {groupOrderInfo?.taxTotal || cateringOrderInfo?.salesTax ? (
              <>
                <div className="order-table-category">
                  {groupOrderInfo?.taxLabel || 'HST (13%)'}:
                </div>
                <div className="order-table-info">
                  $
                  {priceDecimalFormatter(
                    groupOrderInfo?.taxTotal || cateringOrderInfo?.salesTax,
                  )}
                </div>
              </>
            ) : null}
            {groupOrderInfo?.totalOrderAmount ||
            cateringOrderInfo?.totalPrice ? (
              <>
                <div className="order-table-category">Order Total:</div>
                <div className="order-table-info">
                  $
                  {priceDecimalFormatter(
                    groupOrderInfo?.totalOrderAmount ||
                      cateringOrderInfo?.totalPrice,
                  )}
                </div>
              </>
            ) : null}
            {groupOrderInfo?.orderNumber || cateringOrderInfo?.orderNumber ? (
              <>
                <div className="order-table-category">Order Number:</div>
                <div className="order-table-info">
                  {groupOrderInfo?.orderNumber ||
                    cateringOrderInfo?.orderNumber}
                </div>
              </>
            ) : null}
            {orderItem?.orderProcessingAt ? (
              <>
                <div className="order-table-category">
                  {upcoming
                    ? new Date(orderItem?.orderProcessingAt) < new Date()
                      ? 'Order processed on:'
                      : 'Order will be processed on:'
                    : 'Order processed on:'}
                </div>
                <div className="order-table-info">
                  {format(
                    new Date(orderItem?.orderProcessingAt),
                    'LLL  do, uuuu, p',
                  )}
                </div>
              </>
            ) : null}
            {upcoming &&
            dateValid &&
            orderItem?.status !== 'DeliveryReady' &&
            orderItem?.status !== 'Delivered' &&
            orderItem?.status !== 'waiting_for_delivery' &&
            orderItem?.status !== 'delivered' ? (
              groupOrderInfo?.editable || cateringOrderInfo?.editable ? (
                <>
                  {groupOrderInfo?.editable ? (
                    <>
                      {orderItem?.status !== 'created' ||
                      orderItem?.status !== 'detail_entered' ||
                      orderItem?.status !== 'accepting' ? (
                        <a
                          href={groupOrderInfo.adminLink}
                          target="_blank"
                          rel="noreferrer"
                          className="btn_edit"
                        >
                          Edit Order
                        </a>
                      ) : null}
                      {orderItem?.status !== 'declined' ||
                      orderItem?.status !== 'cancelled' ? (
                        <button
                          type="button"
                          className="btn_cancel"
                          onClick={() => handleCancelOrder(groupOrderInfo)}
                        >
                          Cancel order
                        </button>
                      ) : null}
                    </>
                  ) : null}
                  {!upcoming ? (
                    <>
                      {orderItem?.status !== 'New' ||
                      orderItem?.status !== 'CardSetup' ||
                      orderItem?.status !== 'Accepted' ||
                      orderItem?.status !== 'SetupIntentCreated' ||
                      orderItem?.status !== 'CardPaymentError' ? (
                        <a
                          href={`/chefs/${orderItem.presetSlug}/${orderItem.id}`}
                          className="btn_edit"
                        >
                          View Item
                        </a>
                      ) : null}
                      {orderItem?.status !== 'delivery_ready' ||
                      orderItem?.status !== 'canceled' ||
                      orderItem?.status !== 'declined' ? (
                        <button
                          type="button"
                          className="btn_cancel"
                          onClick={() => handleCancelOrder(cateringOrderInfo)}
                        >
                          Cancel order
                        </button>
                      ) : null}
                    </>
                  ) : defaultView ? (
                    <>
                      {orderItem?.status !== 'New' ||
                      orderItem?.status !== 'CardSetup' ||
                      orderItem?.status !== 'Accepted' ||
                      orderItem?.status !== 'SetupIntentCreated' ||
                      orderItem?.status !== 'CardPaymentError' ? (
                        <a
                          href={`/chefs/${orderItem.presetSlug}/${orderItem.id}`}
                          className="btn_edit"
                        >
                          {/**Edit Order */}
                          Edit order
                        </a>
                      ) : null}
                      {orderItem?.status !== 'delivery_ready' ||
                      orderItem?.status !== 'canceled' ||
                      orderItem?.status !== 'declined' ? (
                        <button
                          type="button"
                          className="btn_cancel"
                          onClick={() => handleCancelOrder(cateringOrderInfo)}
                        >
                          {/**Cancel Order */}
                          Cancel order
                        </button>
                      ) : null}
                    </>
                  ) : cateringOrderInfo?.editable ? (
                    <>
                      {orderItem?.status !== 'New' ||
                      orderItem?.status !== 'CardSetup' ||
                      orderItem?.status !== 'Accepted' ||
                      orderItem?.status !== 'SetupIntentCreated' ||
                      orderItem?.status !== 'CardPaymentError' ? (
                        <a
                          href={`/chefs/${orderItem.presetSlug}/${orderItem.id}`}
                          className="btn_edit"
                        >
                          Edit Order
                        </a>
                      ) : null}
                      {orderItem?.status !== 'delivery_ready' ||
                      orderItem?.status !== 'canceled' ||
                      orderItem?.status !== 'declined' ? (
                        <button
                          type="button"
                          className="btn_cancel"
                          onClick={() => handleCancelOrder(cateringOrderInfo)}
                        >
                          Cancel order
                        </button>
                      ) : null}
                    </>
                  ) : null}
                </>
              ) : (
                <div className="table-status-green request_info mt-4">
                  {orderItem?.status !== 'New'
                    ? 'Your order is being prepared for delivery!'
                    : 'This order hasn&apos;t been accepted yet. You will only be able to edit or cancel this order once accepted.'}
                </div>
              )
            ) : (cateringOrderInfo?.id &&
                (orderItem.status === 'DeliveryReady' ||
                  orderItem.status === 'Delivered')) ||
              (groupOrderInfo?.id &&
                (orderItem.status === 'waiting_for_delivery' ||
                  orderItem.status === 'delivered')) ? (
              <>
                {Loader}
                <button
                  type="button"
                  className="btn_edit"
                  onClick={() => {
                    if (cateringOrderInfo?.id) {
                      getCateringOrderPdf(cateringOrderInfo?.id);
                    }
                    if (groupOrderInfo?.id) {
                      getGroupOrderPdf(groupOrderInfo?.id);
                    }
                  }}
                >
                  Download PDF
                </button>
                {addExtraItem && orderItem?.orderType == 'Catering Order' ? (
                  <a
                    onClick={async () => {
                      setIsLoading(true);
                      try {
                        await createLastMinuteAddItem(orderItem?.id, history);
                      } catch (e) {
                        setIsLoading(false);
                        store.addNotification({
                          message:
                            'Sorry, we are not able to create the add on order. Please try again later.',
                          type: 'danger',
                          insert: 'top',
                          container: 'top-right',
                          animationIn: ['animated', 'bounceIn'],
                          animationOut: ['animated', 'fadeOut'],
                          dismiss: {
                            duration: 4000,
                          },
                        });
                      } finally {
                        setIsLoading(false);
                      }
                      setIsLoading(false);
                    }}
                    className="btn_addon"
                  >
                    Add Addon Order
                  </a>
                ) : null}
              </>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

CardItem.propTypes = {
  orderItem: PropTypes.shape({
    id: PropTypes.number,
    totalAmount: PropTypes.number,
    deliveryDate: PropTypes.string,
    deliveryDateTime: PropTypes.string,
    presetName: PropTypes.string,
    displayStatus: PropTypes.string,
    status: PropTypes.string,
    presetSlug: PropTypes.string,
    orderProcessingAt: PropTypes.string,
  }),
  cateringOrderInfo: PropTypes.shape({
    purchaseItems: PropTypes.arrayOf({
      menuName: PropTypes.string,
      menuPrice: PropTypes.number,
      quantity: PropTypes.number,
    }),
    orderNumber: PropTypes.string,
    chefName: PropTypes.string,
    presetId: PropTypes.number,
    displayDeliveryAddress: PropTypes.string,
    companyName: PropTypes.string,
    lastName: PropTypes.string,
    firstName: PropTypes.string,
    phoneNumber: PropTypes.number,
    moreDetail: PropTypes.string,
    totalPrice: PropTypes.number,
    salesTax: PropTypes.number,
    deliveryFee: PropTypes.number,
    itemsTotal: PropTypes.number,
    id: PropTypes.number,
    editable: PropTypes.bool,
    extraInfo: PropTypes.shape({
      numCutlery: PropTypes.number,
      servingStyle: PropTypes.string,
    }),
  }),
  groupOrderInfo: PropTypes.shape({
    taxTotal: PropTypes.number,
    chefName: PropTypes.string,
    taxLabel: PropTypes.string,
    totalOrderAmount: PropTypes.number,
    subTotalCents: PropTypes.number,
    deliveryFeeCents: PropTypes.number,
    deliveryInstruction: PropTypes.string,
    deliveryPhoneNumber: PropTypes.string,
    deliveryContactPerson: PropTypes.string,
    displayDeliveryAddress: PropTypes.string,
    companyName: PropTypes.string,
    adminLink: PropTypes.string,
    sharableLink: PropTypes.string,
    headCount: PropTypes.number,
    status: PropTypes.string,
    id: PropTypes.number,
    orderNumber: PropTypes.string,
    createdAt: PropTypes.number,
    editable: PropTypes.bool,
  }),
  upcoming: PropTypes.bool,
  setCancelOrderId: PropTypes.func,
  getStatus: PropTypes.func,
  getCateringOrderPdf: PropTypes.func,
  getGroupOrderPdf: PropTypes.func,
  createLastMinuteAddItem: PropTypes.func,
};

CardItem.defaultProps = {
  orderItem: null,
  cateringOrderInfo: null,
  groupOrderInfo: null,
  upcoming: false,
  setCancelOrderId: () => {},
  getStatus: () => {},
  getCateringOrderPdf: () => {},
  getGroupOrderPdf: () => {},
  createLastMinuteAddItem: () => {},
};
export default CardItem;
