/* eslint-disable import/no-cycle */
import React from 'react';
import { Helmet } from 'react-helmet';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import ReactNotification from 'react-notifications-component';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import 'react-notifications-component/dist/theme.css';

import HomePage from 'pages/Home';
import BookDemo from 'pages/BookDemo';
import AccountSettings from 'pages/Settings';
import Favorites from 'pages/Favorites';
import Orders from 'pages/Orders';
import MadeForYou from 'pages/MadeForYou';
import BrowseRestaurants from 'pages/BrowseRestaurants';
import PresetDetail from 'pages/PresetDetail';
import RestaurantDetails from 'pages/RestaurantDetails';
import Checkout from 'pages/Checkout';
import CheckoutCompleted from 'pages/Checkout/CheckoutCompleted';
import Header from 'components/common/Header';
import Footer from 'components/common/Footer';
import PageNotFound from 'components/PageNotFound';
import SignUpModal from 'components/common/SignUpModal';
import SignInModal from 'components/common/SignInModal';
import ForgotPasswordModal from 'components/common/ForgotPasswordModal';
import SignUpConfirmModal from 'components/common/SignUpConfirmModal';

// import 'bootstrap/dist/css/bootstrap.min.css';
// don't need bootstrap css as our design is based on custom libs.css
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
// don't need this input here. Instead we will use downloaded CDN inside assets
import './App.css';
import ScrollToTop from 'components/common/ScrollToTop';
import AboutUS from 'pages/AboutUs';
import OrderEdit from 'pages/OrderEdit';
import Faq from 'pages/Faq';
import ResetPassword from 'pages/ReserPassword';
import Payments from 'pages/Payments';
import AddLastMinuteItem from 'pages/AddLastMinuteItem';
import AddonCompleted from 'pages/Checkout/AddonCompleted';
Sentry.init({
  dsn: process.env.REACT_SENTRY_DSN,
  integrations: [new BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.1,
});

function App() {
  const user = useSelector(state => get(state, 'user.user'));
  const userSignedIn = user && user.confirmed;
  const { search } = useLocation();

  return (
    <>
      <Helmet>
        <title>
          Catered Club | Bring happiness and smiles to you and your people. We
          are the number one option for office catering and individual food
          orders online.
        </title>
        <meta
          name="description"
          content="The #1 office food ordering platform online. Consider us your extended family of food experts. We curate the best food options in your city so all you have to do is order and enjoy."
        />
      </Helmet>

      <ReactNotification />
      <Header />
      <Switch>
        <Redirect from="/confirmation" to={`/${search}`} />
        <Route
          exact
          path="/"
          component={userSignedIn ? BrowseRestaurants : HomePage}
        />
        <Route exact path="/book-demo" component={BookDemo} />
        <Route exact path="/faq" component={Faq} />
        <Route
          exact
          path="/accounts/reset-password"
          component={ResetPassword}
        />
        <Route exact path="/settings" component={AccountSettings} />
        <Route
          exact
          path="/payments"
          render={() => (userSignedIn ? <Payments /> : <Redirect to="/" />)}
          // component={Payments}
        />
        <Route exact path="/my-orders" component={Orders} />
        <Route exact path="/favorites" component={Favorites} />
        <Route exact path="/browse-presets" component={MadeForYou} />
        <Route exact path="/browse-restaurants" component={BrowseRestaurants} />
        <Route exact path="/preset-detail/:id" component={PresetDetail} />
        <Route exact path="/chefs/:slug" component={RestaurantDetails} />
        <Route exact path="/chefs/:slug/:order_id" component={OrderEdit} />
        <Route exact path="/checkout" component={Checkout} />
        <Route exact path="/checkout-completed" component={CheckoutCompleted} />
        <Route exact path="/about-us" component={AboutUS} />
        <Route exact path="/complete-addon-order" component={AddonCompleted} />
        <Route exact path="/add_on_order/chefs" component={AddLastMinuteItem} />
        <Route exact path="/add_on_checkout" component={Checkout} />
        <Route component={PageNotFound} />
      </Switch>
      <ScrollToTop />
      {/* Because when path is changed, we should go to top of the page */}
      <SignInModal />
      <SignUpModal />
      <SignUpConfirmModal />
      <ForgotPasswordModal />
      <Footer />
    </>
  );
}

export default App;
