import React from 'react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';

const AddonCompleted = ({ location: { state } }) => {
  const { orderDateTime } = state;
  return (
    <div className="container d-flex justify-content-center">
      <div className="checkout-completed">
        <div
          className="checkout-completed--content"
          style={{ marginBottom: 110 }}
        >
          <img src="/assets/img/order-sent.svg" alt="order-sent" />
          <h2 className="my-4">
            Cannot be proceed!
            <br />
            This order has already been delivered at{' '}
            {format(new Date(orderDateTime), 'yyyy-mm-dd hh:mm')}
          </h2>

          {/* <p className="mb-5">You will receive a response shortly!</p> */}
          <Link to="/">Go to Home</Link>
        </div>
      </div>
    </div>
  );
};

export default AddonCompleted;
