import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const DatePickerField = ({
  name,
  value,
  onChange,
  onFocus,
  onBlur,
  showTimeSelect,
  showTimeSelectOnly,
  className,
  disabled,
}) => {
  const timeProps = showTimeSelect
    ? {
        ...{ showTimeSelect, showTimeSelectOnly },
        timeIntervals: 30,
        timeCaption: 'Time',
        dateFormat: 'h:mm aa',
      }
    : {};

  return (
    <DatePicker
      {...{ onBlur, onFocus }}
      minDate={Date.now()}
      selected={(value && new Date(value)) || null}
      wrapperClassName="w-100"
      className={`form-control ${className}`}
      disabled={disabled}
      onChange={val => {
        onChange(name, val);
      }}
      {...timeProps}
    />
  );
};

DatePickerField.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.instanceOf(Date) || null,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  showTimeSelect: PropTypes.bool,
  showTimeSelectOnly: PropTypes.bool,
  className: PropTypes.string,
};

DatePickerField.defaultProps = {
  id: '',
  value: null,
  showTimeSelect: false,
  showTimeSelectOnly: false,
  className: '',
  onChange: () => {},
  onFocus: () => {},
  onBlur: () => {},
};

export default DatePickerField;
