import React, { useState } from 'react';
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useDispatch } from 'react-redux';
import { onCreatePaymentMethod } from 'redux/actions/paymentActions';
import LoadingAnimation from 'components/common/LoadingAnimation';
import { store } from 'react-notifications-component';

const StripeCard = ({ activeTab }) => {
  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
  );
  return (
    <Elements stripe={stripePromise}>
      <AddStripCard activeTab={activeTab} />
    </Elements>
  );
};

const cardElementOptions = {
  hidePostalCode: true,
};

const AddStripCard = ({ activeTab }) => {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = async () => {
    try {
      if (!stripe || !elements) return;
      setSubmitting(true);
      const cardElement = elements.getElement(CardElement);
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });
      if (error) {
        console.error(error.message);
        store.addNotification({
          title: 'Card Addition Error!',
          message: error.message,
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animated', 'bounceIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 4000,
          },
        });
        setSubmitting(false);
        return;
      }
      dispatch(onCreatePaymentMethod({ paymentMethodId: paymentMethod?.id }));
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div
      style={{ padding: '20px', border: '1px solid #ddd', borderRadius: '8px' }}
    >
      {submitting ? <LoadingAnimation /> : null}
      <CardElement key={activeTab} options={cardElementOptions} />
      {/* eslint-disable-next-line react/button-has-type */}
      <button
        onClick={handleSubmit}
        disabled={!stripe}
        className="btn btn-block btn-primary mt-4"
      >
        Confirm and Save Credit Card
      </button>
    </div>
  );
};
export default StripeCard;
