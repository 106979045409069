import React, {
  // useRef,
  // useEffect,
  useState,
  useMemo,
} from 'react';
// import classnames from 'classnames';
import PropTypes from 'prop-types';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
// import map from 'lodash/map';
import Tab from 'react-bootstrap/Tab';
import { Link, Element } from 'react-scroll';

import { buildPresetItemFromMenu } from 'utils/preset.util';
import { priceDecimalFormatter } from 'formatter/preset.formatter';
import TruncateWrapper from 'components/common/TruncateWrapper';
import { store } from 'react-notifications-component';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
function isMenuAddedToCart(checkoutData, menuId) {
  return checkoutData.some(obj => obj.menuId === menuId);
}

function getQuantity(checkoutData, menuId) {
  const menuObj = checkoutData.find(obj => obj.menuId === menuId);
  if (menuObj && typeof menuObj.quantity === 'number') {
    return menuObj.quantity;
  }
  return 0;
}

const RestaurantMenu = ({
  chefs,
  presetItems,
  setCurrentPresetItem,
  mainHeading,
  checkoutData,
  canonicalLink,
}) => {
  const [activeChef, setActiveChef] = useState(
    chefs && chefs.length > 0 ? chefs[0].id : 0,
  );
  const itemLimit = chefs[0]?.addonMaximumItemsQty;
  const memoizedCategories = useMemo(
    () =>
      chefs.map(chef =>
        groupBy(
          sortBy(chef.menus, ['priceCents']),
          // Need to show menus based on price
          'categoryName',
        ),
      ),
    [chefs, presetItems],
  );

  const menuCategoryNames = useMemo(
    () =>
      activeChef === chefs[0].id
        ? chefs[0].menuCategoryNames
        : chefs[1].menuCategoryNames,
    [memoizedCategories, activeChef],
  );
  const showAddondata = canonicalLink?.pathname;
  return (
    <>
      {showAddondata ? (
        <div className="detailed-view--additional show">
          <div className="container">
            <div className="col-xl-8 pr-xl-4">
              <h2 className="restaurant-menu__heading">{mainHeading}</h2>

              <div className="menu-filter-tabs__wrap-sticky">
                <div className="menu-filter-tabs__wrap-scroll">
                  <div className="home--form__tabs menu-filter-tabs__wrap">
                    {menuCategoryNames.map(category => (
                      <Link
                        key={category}
                        to={category}
                        spy
                        smooth
                        offset={-90}
                        activeClass="active"
                        className={`
                      home--form__tab-link  menu-filter-tab
                    `}
                      >
                        <span className="home--form__tab-link-text">
                          {category}
                        </span>
                      </Link>
                    ))}
                  </div>
                </div>
              </div>

              <Tab.Container
                activeKey={activeChef}
                onSelect={k => setActiveChef(k)}
              >
                <Tab.Content>
                  {chefs.map((chef, index) => (
                    <Tab.Pane eventKey={chef.id} key={chef.id}>
                      {menuCategoryNames.map(category => {
                        const menus = memoizedCategories[index][category];
                        return (
                          <Element
                            key={`${chef.id}_${category}`}
                            name={category}
                            className="detailed-view--additional-section card entree"
                          >
                            <div className="card-header">{category}</div>
                            <div className="card-body">
                              {menus &&
                                menus.length > 0 &&
                                menus.map(menu => {
                                  const {
                                    name,
                                    mainImageUrl,
                                    dietaryTypes,
                                    priceCents,
                                    description,
                                  } = menu;
                                  return (
                                    <div
                                      key={menu.id}
                                      className={`
                              detailed-view--additional-item detailed-view--menu-item 
                              ${
                                isMenuAddedToCart(checkoutData, menu.id)
                                  ? 'active'
                                  : ''
                              }
                            `}
                                    >
                                      <div className="left">
                                        <img
                                          src={mainImageUrl}
                                          alt="menu_img"
                                          onClick={() => {
                                            setCurrentPresetItem(
                                              buildPresetItemFromMenu(menu),
                                            );
                                          }}
                                        />
                                        <div className="info">
                                          <h4>{name}</h4>
                                          {dietaryTypes && (
                                            <div className="tags">
                                              {dietaryTypes
                                                .split(',')
                                                .map(dietaryType => (
                                                  <li key={dietaryType}>
                                                    {dietaryType}
                                                  </li>
                                                ))}
                                            </div>
                                          )}
                                          <p className="detailed-view--menu-item-desc">
                                            <TruncateWrapper
                                              text={description}
                                            />
                                          </p>
                                        </div>
                                      </div>
                                      <div className="right">
                                        <div className="d-flex">
                                          <div className="badge badge-pill badge-primary">
                                            ${priceDecimalFormatter(priceCents)}
                                          </div>
                                        </div>
                                        <div className="detailed-view--menu__cta-wrap">
                                          {/* <button
                                            className="btn btn-outline-primary"
                                            type="button"
                                            onClick={() => {
                                              setCurrentPresetItem(
                                                buildPresetItemFromMenu(menu),
                                              );
                                            }}
                                          > */}
                                          <button
                                            className="btn btn-outline-primary"
                                            type="button"
                                            onClick={() => {
                                              if (
                                                checkoutData?.length ===
                                                itemLimit
                                              ) {
                                                store.addNotification({
                                                  message: `You cannot add more than ${itemLimit} items.`,
                                                  type: 'danger',
                                                  insert: 'top',
                                                  container: 'top-right',
                                                  animationIn: [
                                                    'animated',
                                                    'bounceIn',
                                                  ],
                                                  animationOut: [
                                                    'animated',
                                                    'fadeOut',
                                                  ],
                                                  dismiss: {
                                                    duration: 4000,
                                                  },
                                                });
                                                return;
                                              }
                                              setCurrentPresetItem(
                                                buildPresetItemFromMenu(menu),
                                              );
                                            }}
                                          >
                                            {isMenuAddedToCart(
                                              checkoutData,
                                              menu.id,
                                            )
                                              ? 'Update Item'
                                              : 'Add Item'}
                                          </button>
                                          {isMenuAddedToCart(
                                            checkoutData,
                                            menu.id,
                                          ) && (
                                            <div className="menu__quantity-badge">
                                              <img
                                                src="/assets/img/cart.svg"
                                                alt="cart"
                                              />
                                              {getQuantity(
                                                checkoutData,
                                                menu.id,
                                              )}{' '}
                                              in cart
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          </Element>
                        );
                      })}
                    </Tab.Pane>
                  ))}
                </Tab.Content>
              </Tab.Container>
            </div>
          </div>
        </div>
      ) : (
        <div className="detailed-view--additional show">
          <div className="container">
            <div className="col-xl-8 pr-xl-4">
              <h2 className="restaurant-menu__heading">{mainHeading}</h2>

              <div className="menu-filter-tabs__wrap-sticky">
                <div className="menu-filter-tabs__wrap-scroll">
                  <div className="home--form__tabs menu-filter-tabs__wrap">
                    {menuCategoryNames.map(category => (
                      <Link
                        key={category}
                        to={category}
                        spy
                        smooth
                        offset={-90}
                        activeClass="active"
                        className={`
                      home--form__tab-link  menu-filter-tab
                    `}
                      >
                        <span className="home--form__tab-link-text">
                          {category}
                        </span>
                      </Link>
                    ))}
                  </div>
                </div>
              </div>

              <Tab.Container
                activeKey={activeChef}
                onSelect={k => setActiveChef(k)}
              >
                <Tab.Content>
                  {chefs.map((chef, index) => (
                    <Tab.Pane eventKey={chef.id} key={chef.id}>
                      {menuCategoryNames.map(category => {
                        const menus = memoizedCategories[index][category];
                        return (
                          <Element
                            key={`${chef.id}_${category}`}
                            name={category}
                            className="detailed-view--additional-section card entree"
                          >
                            <div className="card-header">{category}</div>
                            <div className="card-body">
                              {menus &&
                                menus.length > 0 &&
                                menus.map(menu => {
                                  const {
                                    name,
                                    mainImageUrl,
                                    dietaryTypes,
                                    priceCents,
                                    description,
                                  } = menu;
                                  return (
                                    <div
                                      key={menu.id}
                                      className={`
                              detailed-view--additional-item detailed-view--menu-item 
                              ${
                                isMenuAddedToCart(checkoutData, menu.id)
                                  ? 'active'
                                  : ''
                              }
                            `}
                                    >
                                      <div className="left">
                                        <img
                                          src={mainImageUrl}
                                          alt="menu_img"
                                          onClick={() => {
                                            setCurrentPresetItem(
                                              buildPresetItemFromMenu(menu),
                                            );
                                          }}
                                        />
                                        <div className="info">
                                          <h4>{name}</h4>
                                          {dietaryTypes && (
                                            <div className="tags">
                                              {dietaryTypes
                                                .split(',')
                                                .map(dietaryType => (
                                                  <li key={dietaryType}>
                                                    {dietaryType}
                                                  </li>
                                                ))}
                                            </div>
                                          )}
                                          <p className="detailed-view--menu-item-desc">
                                            <TruncateWrapper
                                              text={description}
                                            />
                                          </p>
                                        </div>
                                      </div>
                                      <div className="right">
                                        <div className="d-flex">
                                          <div className="badge badge-pill badge-primary">
                                            ${priceDecimalFormatter(priceCents)}
                                          </div>
                                        </div>
                                        <div className="detailed-view--menu__cta-wrap">
                                          <button
                                            className="btn btn-outline-primary"
                                            type="button"
                                            onClick={() => {
                                              setCurrentPresetItem(
                                                buildPresetItemFromMenu(menu),
                                              );
                                            }}
                                          >
                                            {isMenuAddedToCart(
                                              checkoutData,
                                              menu.id,
                                            )
                                              ? 'Update Item'
                                              : 'Add Item'}
                                          </button>
                                          {isMenuAddedToCart(
                                            checkoutData,
                                            menu.id,
                                          ) && (
                                            <div className="menu__quantity-badge">
                                              <img
                                                src="/assets/img/cart.svg"
                                                alt="cart"
                                              />
                                              {getQuantity(
                                                checkoutData,
                                                menu.id,
                                              )}{' '}
                                              in cart
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          </Element>
                        );
                      })}
                    </Tab.Pane>
                  ))}
                </Tab.Content>
              </Tab.Container>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

RestaurantMenu.propTypes = {
  chefs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      menus: PropTypes.arrayOf(PropTypes.shape({})),
      menuCategoryNames: PropTypes.arrayOf(PropTypes.string),
    }),
  ),
  presetItems: PropTypes.arrayOf(PropTypes.shape({})),
  setCurrentPresetItem: PropTypes.func,
  mainHeading: PropTypes.string,
  checkoutData: PropTypes.array.isRequired,
};

RestaurantMenu.defaultProps = {
  chefs: [],
  presetItems: [],
  setCurrentPresetItem: () => {},
  mainHeading: 'Menu',
};
export default RestaurantMenu;
