/* eslint-disable jsx-a11y/no-onchange */
import React, { useEffect, useState, useMemo } from 'react';
import map from 'lodash/map';
import orderBy from 'lodash/orderBy';
import PropTypes from 'prop-types';
import { getPresetDetail } from 'api/presetApi';
import LoadingAnimation from 'components/common/LoadingAnimation';
import QuickViewModal from 'components/common/QuickViewModal';
import MenuCard from 'components/common/MenuCard';

import './index.scss';
import { SORT_OPTIONS } from 'consts/presets';
import MenuCardPlaceholder from './MenuCardPlaceholder';

const PresetsCategory = ({
  name,
  presets,
  isFavorites,
  isSavedPresets,
  removeHandler,
  showSortOptions,
}) => {
  const [quickViewModalShown, setQuickViewModalShown] = useState(false);
  const [loading, setLoading] = useState(false);
  const [presetId, setPresetId] = useState(null);
  const [preset, setPreset] = useState({});
  const defaultNumbersToShow = Math.min(4, presets.length);
  const [loadingMore, setLoadingMore] = useState(false);
  const [numberToShow, setNumberToShow] = useState(defaultNumbersToShow); // initially show only 4 presets
  const [sortOption, setSortOption] = useState(SORT_OPTIONS.DEFAULT);

  useEffect(() => {
    if (presetId !== null) {
      setLoading(true);
      const fetchData = async () => {
        const preset = await getPresetDetail(presetId);
        setPreset(preset);
        setLoading(false);
      };
      fetchData();
      // fetch preset data
    }
  }, [presetId]);

  const clickHandler = id => {
    setPresetId(id);
    setQuickViewModalShown(true);
  };

  const showMoreHandler = () => {
    if (numberToShow < presets.length) {
      setLoadingMore(true);
      setTimeout(() => {
        setLoadingMore(false);
        setNumberToShow(numberToShow + 8);
      }, 1000);
    } else {
      setNumberToShow(defaultNumbersToShow);
    }
  };

  const sortedPresets = useMemo(() => {
    switch (sortOption) {
      case SORT_OPTIONS.DEFAULT:
        return presets;
      case SORT_OPTIONS.SORT_BY_PRICE_ASC:
        return orderBy(presets, ['pricePerPersonCents'], ['asc']);
      case SORT_OPTIONS.SORT_BY_PRICE_DESC:
        return orderBy(presets, ['pricePerPersonCents'], ['desc']);
      case SORT_OPTIONS.SORT_BY_HEADCOUNT_ASC:
        return orderBy(presets, ['numberOfGuests'], ['asc']);
      case SORT_OPTIONS.SORT_BY_HEADCOUNT_DESC:
        return orderBy(presets, ['numberOfGuests'], ['desc']);
      default:
        return presets;
    }
  }, [sortOption, presets]);

  return (
    <section key={name} className="made--section mb-5">
      <div className="made--section-header">
        <div className="left">
          <h3>{name}</h3>
          {defaultNumbersToShow !== presets.length && ( // No need to show button if there are less than 4 presets
            <button
              type="button"
              className="btn btn-link show-more mt-0"
              onClick={showMoreHandler}
            >
              {numberToShow < presets.length ? 'SHOW MORE' : 'SHOW LESS'}
            </button>
          )}
        </div>
        <div className="right">
          {showSortOptions && (
            <>
              <span className="text-muted">Sort by</span>
              <select
                value={sortOption}
                onChange={e => {
                  setSortOption(e.currentTarget.value);
                }}
              >
                {map(SORT_OPTIONS, (option, key) => (
                  <option key={key}>{option}</option>
                ))}
              </select>
            </>
          )}
          {/* <div className="help">
            <svg
              className="icon icon-help"
              data-toggle="popover"
              data-placement="bottom"
              data-trigger="hover"
              data-html="true"
              data-content="Don’t worry if you have an uneven headcount, you can always customize the preset to add more.
<br> <br> For example if you have 53 people, you can sort by “Serves 50” and add 3 additional meals."
            >
              <use xlinkHref="#spriteIcon-help" />
            </svg>
          </div> */}
        </div>
      </div>
      <div className="made--section-content mb-xl-4">
        {loading && <LoadingAnimation />}
        {quickViewModalShown && !loading && (
          <QuickViewModal
            preset={preset}
            isSavedPresets={isSavedPresets}
            onHide={() => {
              setQuickViewModalShown(false);
            }}
          />
        )}
        <div className="slick-initialized slick-slider slick slick-grid home--carousel">
          <div className="slick-list">
            <div className="slick-track slick-track--wrapped">
              {sortedPresets &&
                sortedPresets.length > 0 &&
                sortedPresets.slice(0, numberToShow).map(preset => {
                  return (
                    <div
                      key={preset.id + preset.slug}
                      className="made--meal meal-card slide slide--25"
                    >
                      <MenuCard
                        {...{
                          preset,
                          isFavorites,
                          isSavedPresets,
                          removeHandler,
                          clickHandler,
                        }}
                      />
                    </div>
                  );
                })}
              {loadingMore && <MenuCardPlaceholder />}
            </div>

            {numberToShow > defaultNumbersToShow && (
              <div className="more">
                <button
                  className="alert alert-danger w-100"
                  type="button"
                  onClick={showMoreHandler}
                >
                  {numberToShow < presets.length
                    ? `Show More ${name}`
                    : 'Show Less'}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

PresetsCategory.propTypes = {
  name: PropTypes.string,
  presets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    }),
  ),
  isSavedPresets: PropTypes.bool,
  isFavorites: PropTypes.bool,
  removeHandler: PropTypes.func,
  showSortOptions: PropTypes.bool,
};

PresetsCategory.defaultProps = {
  name: '',
  presets: [],
  isSavedPresets: false,
  isFavorites: false,
  removeHandler: () => {},
  showSortOptions: true,
};

export default PresetsCategory;
