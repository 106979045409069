/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import './index.scss';
import CardItem from './Card';
import Tab from 'react-bootstrap/Tab';
import { Nav } from 'react-bootstrap';
import { Accordion, Card } from 'react-bootstrap';

import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
const OrderView = ({ onClose, ...props }) => {
  const { upcoming, orderItem } = props;
  const [openIndex, setOpenIndex] = useState(null);

  const handleToggle = index => {
    setOpenIndex(prevIndex => (prevIndex === index ? null : index));
  };

  const defaultView = true;

  const { addLastTimeItem } = useSelector(state => state.caterings);
  const isAdd =
    orderItem?.orderType == 'Catering Order' && addLastTimeItem.length > 0;

  return (
    <Modal className="custom-tabs" show size="lg" onHide={onClose}>
      <button className="close" type="button" onClick={onClose} />
      {//  openAddonTab &&
      isAdd ? (
        <Tab.Container defaultActiveKey="chefs">
          {/* Navigation for tabs */}
          <Nav variant="tabs" style={{ width: '100%', border: 'hidden' }}>
            <Nav.Item>
              <Nav.Link eventKey="chefs">Original Order</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="activeItem">Addon Orders</Nav.Link>
            </Nav.Item>
          </Nav>

          {/* Tab Content */}
          <Tab.Content>
            <Tab.Pane eventKey="chefs" style={{ width: '100%' }}>
              <Modal.Body className="modal-body modal--edit-qty">
                <CardItem {...props} defaultView={defaultView} />
              </Modal.Body>
            </Tab.Pane>

            <Tab.Pane eventKey="activeItem" style={{ width: '100%' }}>
              <Modal.Body className="modal-body modal--edit-qty">
                <div style={{ width: '100%' }}>
                  <>
                    <Accordion activeKey={String(openIndex)}>
                      {addLastTimeItem.map((item, index) => (
                        <Card key={index} style={{ width: '100%' }}>
                          <Accordion.Toggle
                            as={Card.Header}
                            eventKey={String(index)} // Ensure each item has its correct index as eventKey
                            onClick={() => handleToggle(index)}
                            style={{
                              fontWeight: 'bold',
                              cursor: 'pointer',
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}
                          >
                            {' '}
                            <span>
                              {' '}
                              Addon Order # {index + 1}{' '}
                              {item.orderNumber
                                ? `- ${item.orderNumber}`
                                : 'Order Details'}
                            </span>
                            <FontAwesomeIcon
                              icon={
                                openIndex === index
                                  ? faChevronUp
                                  : faChevronDown
                              }
                            />
                          </Accordion.Toggle>
                          <Accordion.Collapse eventKey={String(index)}>
                            <CardItem {...props} cateringOrderInfo={item} />
                          </Accordion.Collapse>
                        </Card>
                      ))}
                    </Accordion>
                  </>
                </div>
              </Modal.Body>

              {/* <CardItem {...props} isAdd={isAdd} /> */}
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      ) : (
        <Modal.Body className="modal-body modal--edit-qty">
          <CardItem {...props} />
        </Modal.Body>
      )}
    </Modal>
  );
};

OrderView.propTypes = {
  orderItem: PropTypes.shape({
    id: PropTypes.number,
    totalAmount: PropTypes.number,
    deliveryDate: PropTypes.string,
    deliveryDateTime: PropTypes.string,
    presetName: PropTypes.string,
    displayStatus: PropTypes.string,
    status: PropTypes.string,
    presetSlug: PropTypes.string,
    orderProcessingAt: PropTypes.string,
  }),
  cateringOrderInfo: PropTypes.shape({
    purchaseItems: PropTypes.arrayOf({
      menuName: PropTypes.string,
      menuPrice: PropTypes.number,
      quantity: PropTypes.number,
    }),
    orderNumber: PropTypes.string,
    chefName: PropTypes.string,
    presetId: PropTypes.number,
    displayDeliveryAddress: PropTypes.string,
    companyName: PropTypes.string,
    lastName: PropTypes.string,
    firstName: PropTypes.string,
    phoneNumber: PropTypes.number,
    moreDetail: PropTypes.string,
    totalPrice: PropTypes.number,
    salesTax: PropTypes.number,
    deliveryFee: PropTypes.number,
    itemsTotal: PropTypes.number,
    id: PropTypes.number,
    editable: PropTypes.bool,
    extraInfo: PropTypes.shape({
      numCutlery: PropTypes.number,
      servingStyle: PropTypes.string,
    }),
  }),
  groupOrderInfo: PropTypes.shape({
    taxTotal: PropTypes.number,
    chefName: PropTypes.string,
    taxLabel: PropTypes.string,
    totalOrderAmount: PropTypes.number,
    subTotalCents: PropTypes.number,
    deliveryFeeCents: PropTypes.number,
    deliveryInstruction: PropTypes.string,
    deliveryPhoneNumber: PropTypes.string,
    deliveryContactPerson: PropTypes.string,
    displayDeliveryAddress: PropTypes.string,
    companyName: PropTypes.string,
    adminLink: PropTypes.string,
    sharableLink: PropTypes.string,
    headCount: PropTypes.number,
    status: PropTypes.string,
    id: PropTypes.number,
    orderNumber: PropTypes.string,
    createdAt: PropTypes.number,
    editable: PropTypes.bool,
  }),
  upcoming: PropTypes.bool,
  onClose: PropTypes.func,
  setCancelOrderId: PropTypes.func,
  getStatus: PropTypes.func,
  getCateringOrderPdf: PropTypes.func,
  getGroupOrderPdf: PropTypes.func,
  createLastMinuteAddItem: PropTypes.func,
};

OrderView.defaultProps = {
  orderItem: null,
  cateringOrderInfo: null,
  groupOrderInfo: null,
  upcoming: false,
  onClose: () => {},
  setCancelOrderId: () => {},
  getStatus: () => {},
  getCateringOrderPdf: () => {},
  getGroupOrderPdf: () => {},
  createLastMinuteAddItem: () => {},
};
export default OrderView;
