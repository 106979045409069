import { beginApiCall, apiCallError } from 'redux/actions/apiStatusActions';
import { store } from 'react-notifications-component';
import { searchRestaurants, getRestaurant } from 'api/restaurantApi';
import { batch } from 'react-redux';
import { flattenChefs } from 'utils/restaurants.util';
import { saveForLater, removeFavorite, addFavorite } from 'api/presetApi';
import {
  RESTAURANTS_FETCH_REQUESTED,
  RESTAURANTS_FETCH_SUCCESS,
  RESTAURANTS_FETCH_FAILED,
  TOGGLE_FILTER_TAG,
  SET_SELECTED_TAGS,
  RESTAURANT_DETAIL_FETCH_REQUESTED,
  RESTAURANT_DETAIL_FETCH_SUCCESS,
  RESTAURANT_DETAIL_FETCH_FAILED,
  SET_CHECKOUT_DATA,
  SET_PRESET,
  RESET_CHECKOUT_DATA,
  SAVE_CUSTOM_CHEF_PRESET_REQUESTED,
  SAVE_CUSTOM_CHEF_PRESET_SUCCEEDED,
  SAVE_CUSTOM_CHEF_PRESET_FAILED,
  TOGGLE_FAVORITE_STATUS_REQUESTED,
  TOGGLE_FAVORITE_STATUS_SUCCEEDED,
  TOGGLE_FAVORITE_STATUS_FAILED,
  RESTAURANT_DETAIL_FETCH_FOR_ADD_ON_SUCCESS,
} from './actionTypes';

// START Browse Restaurants page
export const loadRestaurantsRequested = (page, loadingMore) => {
  return {
    type: RESTAURANTS_FETCH_REQUESTED,
    payload: { page, loadingMore },
  };
};

export const loadRestaurantsSuccess = (response, loadingMore) => {
  const { presets, metaData } = response;
  return {
    type: RESTAURANTS_FETCH_SUCCESS,
    payload: {
      chefs: flattenChefs(presets.allPresets || []),
      favoriteRestaurants: presets.favoriteRestaurants,
      savedForLaterRestaurants: presets.savedForLaterRestaurants,
      filterTags: metaData.presetCategoryNames,
      currentPage: metaData.currentPage,
      fullCount: metaData.fullCount,
      totalPage: metaData.totalPage,
      loadingMore,
    },
  };
};

export const loadRestaurantsFailed = error => {
  return { type: RESTAURANTS_FETCH_FAILED, payload: { error } };
};

export const toggleFilterTag = tag => {
  return { type: TOGGLE_FILTER_TAG, payload: { tag } };
};

export const setSelectedTags = tags => {
  return { type: SET_SELECTED_TAGS, payload: { tags } };
};

export const setCheckoutData = checkoutData => {
  return { type: SET_CHECKOUT_DATA, payload: { checkoutData } };
};

export const resetCheckoutData = () => {
  return { type: RESET_CHECKOUT_DATA };
};

export const setPreset = preset => {
  return { type: SET_PRESET, payload: { preset } };
};

// Thunk
export const loadRestaurants = ({ queryParams, page, loadingMore }) => {
  return async dispatch => {
    batch(() => {
      dispatch(beginApiCall());
      dispatch(loadRestaurantsRequested(page, loadingMore));
    });

    try {
      const response = await searchRestaurants({ queryParams, page });
      dispatch(loadRestaurantsSuccess(response, loadingMore));
    } catch (error) {
      batch(() => {
        dispatch(apiCallError(error));
        dispatch(loadRestaurantsFailed(error));
      });
      throw error;
    }
  };
};
// End of Browse Restaurants page

export const loadRestaurantDetailsRequested = () => {
  return { type: RESTAURANT_DETAIL_FETCH_REQUESTED };
};

export const loadRestaurantDetailsSuccess = preset => {
  return {
    type: RESTAURANT_DETAIL_FETCH_SUCCESS,
    payload: {
      preset,
    },
  };
};
export const loadRestaurantDetailsForAddOnItemSuccess = preset => {
  return {
    type: RESTAURANT_DETAIL_FETCH_FOR_ADD_ON_SUCCESS,
    payload: {
      preset,
    },
  };
};

export const loadRestaurantDetailsFailed = error => {
  return { type: RESTAURANT_DETAIL_FETCH_FAILED, payload: { error } };
};

// Thunk
export const loadRestaurantDetails = (slug, addOnItem) => {
  return async dispatch => {
    batch(() => {
      dispatch(beginApiCall());
      dispatch(loadRestaurantDetailsRequested());
    });

    try {
      const response = await getRestaurant(slug);
      if (addOnItem) {
        dispatch(loadRestaurantDetailsForAddOnItemSuccess(response))
      } else {
        dispatch(loadRestaurantDetailsSuccess(response));
      }
    } catch (error) {
      batch(() => {
        dispatch(apiCallError(error));
        dispatch(loadRestaurantDetailsFailed(error));
      });
      throw error;
    }
  };
};

// Save custom chef
export const saveCustomChefPresetRequested = () => {
  return { type: SAVE_CUSTOM_CHEF_PRESET_REQUESTED };
};

export const saveCustomChefPresetSucceeded = () => {
  return { type: SAVE_CUSTOM_CHEF_PRESET_SUCCEEDED };
};

export const saveCustomChefPresetFailed = error => {
  return { type: SAVE_CUSTOM_CHEF_PRESET_FAILED, payload: { error } };
};

export const saveCustomChefPreset = (name, preset, checkoutData) => {
  return async dispatch => {
    batch(() => {
      dispatch(beginApiCall());
      dispatch(saveCustomChefPresetRequested());
    });
    try {
      await saveForLater(name, preset, checkoutData);
      // extract relative path
      // const relativeIndex = saved.sharedUrl.indexOf('/chefs/');
      // if (relativeIndex > -1) {
      //   history.push(saved.sharedUrl.slice(relativeIndex));
      // }
      store.addNotification({
        title: 'Preset Saved!',
        message: 'We have emailed you the Link. Check your email.',
        type: 'success',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animated', 'bounceIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 4000,
        },
      });
      dispatch(saveCustomChefPresetSucceeded());
    } catch (error) {
      batch(() => {
        dispatch(apiCallError(error));
        dispatch(saveCustomChefPresetFailed(error));
      });
      throw error;
    }
  };
};

// Save custom chef
export const toggleFavoriteRequested = () => {
  return { type: TOGGLE_FAVORITE_STATUS_REQUESTED };
};

export const toggleFavoriteSucceeded = () => {
  return { type: TOGGLE_FAVORITE_STATUS_SUCCEEDED };
};

export const toggleFavoriteFailed = error => {
  return { type: TOGGLE_FAVORITE_STATUS_FAILED, payload: { error } };
};

export const toggleRestaurantFavorite = (presetId, favorite) => {
  return async dispatch => {
    batch(() => {
      dispatch(beginApiCall());
      dispatch(toggleFavoriteRequested());
    });
    try {
      if (favorite) {
        await removeFavorite(presetId);
        store.addNotification({
          message: 'Removed from your Favorites. You can add it back anytime.',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animated', 'bounceIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 4000,
          },
        });
      } else {
        await addFavorite(presetId);
        store.addNotification({
          message:
            'Added to your Favorites. You can find this preset saved under the Favorites category on your homepage.',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animated', 'bounceIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 4000,
          },
        });
      }
      dispatch(toggleFavoriteSucceeded());
    } catch (error) {
      batch(() => {
        dispatch(apiCallError(error));
        dispatch(toggleFavoriteFailed(error));
      });
      throw error;
    }
  };
};
