import React, { useEffect, useState } from 'react';
import { store } from 'react-notifications-component';
import { fetchFavorites, removeFavoriteOrSaved } from 'redux/favorites/actions';
import { useDispatch, useSelector } from 'react-redux';
import MenuCardPlaceholder from 'pages/MadeForYou/MenuCardPlaceholder';
import PresetsCategory from 'pages/MadeForYou/PresetsCategory';
import NoFavorites from 'pages/MadeForYou/NoFavorites';
import RestaurantCard from 'components/common/RestaurantCard';
import ConfirmModal from 'components/common/ConfirmModal';

import { removeFavorite, removeSavedPreset } from 'api/presetApi';
import LoadingAnimation from 'components/common/LoadingAnimation';

const Favorites = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchFavorites());
  }, []);

  const {
    favoriteRestaurants,
    savedForLaterRestaurants,
    previouslyOrderedRestaurants,
    favoritePresets,
    savedForLaterPresets,
    previouslyOrderedPresets,
    loading,
    loadingRemoveFavoriteOrSaved,
  } = useSelector(state => state.favorites);

  const [favoriteRemoveId, setFavoriteRemoveId] = useState(null);
  const [savedRemoveId, setSavedRemoveId] = useState(null);
  const [showMapModal, setShowMapModal] = useState(false);
  const [mapModalData, setMapModalData] = useState({});

  const loadingPlaceholder = (
    <div className="slick-initialized slick-slider slick slick-grid home--carousel">
      <div className="slick-list">
        <div className="slick-track slick-track--wrapped">
          <MenuCardPlaceholder />
        </div>
      </div>
    </div>
  );

  function openRestaurantLocation({
    firstName,
    shortAddress,
    slug,
    latitude,
    longitude,
  }) {
    setMapModalData({
      firstName,
      shortAddress,
      slug,
      latitude,
      longitude,
    });
    setShowMapModal(true);
  }

  const removeHandler = (presetId, isFavorites, isSavedPresets) => {
    if (isFavorites) {
      setFavoriteRemoveId(presetId);
    } else if (isSavedPresets) {
      setSavedRemoveId(presetId);
    }
  };

  const onRemove = async (presetId, isFavorites) => {
    dispatch(removeFavoriteOrSaved(presetId, isFavorites));
  };

  return (
    <main>
      <div className="container">
        {loadingRemoveFavoriteOrSaved && <LoadingAnimation />}
        {favoriteRemoveId && (
          <ConfirmModal
            show
            description="Are you sure you want to remove from your favorites?"
            confirmText="Remove"
            onConfirm={() => {
              onRemove(favoriteRemoveId, true, false);
              setFavoriteRemoveId(null);
            }}
            onClose={() => {
              setFavoriteRemoveId(null);
            }}
          />
        )}
        {savedRemoveId && (
          <ConfirmModal
            show
            description="Are you sure you want to remove from your saved orders?"
            confirmText="Remove"
            onConfirm={() => {
              onRemove(savedRemoveId, false, true);
              setSavedRemoveId(null);
            }}
            onClose={() => {
              setSavedRemoveId(null);
            }}
          />
        )}

        {loading && loadingPlaceholder}
        {!loading && (
          <>
            <h2 className="page-title mt-0 mb-4">Restaurants</h2>
            {(!favoriteRestaurants || favoriteRestaurants.length === 0) && (
              <NoFavorites restaurants />
            )}
            {favoriteRestaurants && favoriteRestaurants.length > 0 && (
              <div className="made--section-content mb-xl-1">
                <h3>Favorites</h3>
                <div className="slick-initialized slick-slider slick slick-grid">
                  <div className="slick-list">
                    <div className="slick-track slick-track--wrapped pt-1">
                      {favoriteRestaurants.map(chef => {
                        const {
                          id,
                          chefIds,
                          slug,
                          name,
                          distance,
                          shortAddress,
                          cuisineTypes,
                          chefImages,
                          latitude,
                          longitude,
                          distanceUnit,
                          description,
                          presetTags,
                        } = chef;
                        let { profileImageUrl, mainImageUrl } = chefImages[
                          chefIds[0]
                        ];
                        return (
                          <RestaurantCard
                            key={`${id}-${slug}`}
                            firstName={name}
                            profileImageUrl={profileImageUrl}
                            tagNames={cuisineTypes}
                            mainImageUrl={mainImageUrl}
                            favorite
                            onFavoritePage
                            removeHandler={removeHandler}
                            {...{
                              id,
                              latitude,
                              longitude,
                              description,
                              distance,
                              presetTags,
                              distanceUnit,
                              shortAddress,
                              slug: `/chefs/${slug}`,
                              openRestaurantLocation,
                            }}
                          />
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            )}

            {savedForLaterRestaurants && savedForLaterRestaurants.length > 0 && (
              <div className="made--section-content mb-xl-1">
                <h3>Saved For Later</h3>
                <div className="slick-initialized slick-slider slick slick-grid">
                  <div className="slick-list">
                    <div className="slick-track slick-track--wrapped pt-1">
                      {savedForLaterRestaurants.map(chef => {
                        const {
                          id,
                          chefIds,
                          slug,
                          name,
                          distance,
                          searchResultImageUrl,
                          shortAddress,
                          cuisineTypes,
                          chefImages,
                          latitude,
                          longitude,
                          distanceUnit,
                          description,
                          presetTags,
                        } = chef;
                        return (
                          <RestaurantCard
                            key={`${id}-${slug}`}
                            firstName={name}
                            profileImageUrl={searchResultImageUrl}
                            tagNames={cuisineTypes}
                            mainImageUrl={chefImages[chefIds[0]].mainImageUrl}
                            savedOrder
                            onFavoritePage
                            removeHandler={removeHandler}
                            {...{
                              id,
                              latitude,
                              longitude,
                              description,
                              distance,
                              presetTags,
                              distanceUnit,
                              shortAddress,
                              slug: `/chefs/${slug}`,
                              openRestaurantLocation,
                            }}
                          />
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            )}

            {previouslyOrderedRestaurants &&
              previouslyOrderedRestaurants.length > 0 && (
                <div className="made--section-content mb-xl-1">
                  <h3>Recently Ordered</h3>
                  <div className="slick-initialized slick-slider slick slick-grid">
                    <div className="slick-list">
                      <div className="slick-track slick-track--wrapped pt-1">
                        {previouslyOrderedRestaurants.map(chef => {
                          const {
                            id,
                            chefIds,
                            slug,
                            name,
                            distance,
                            searchResultImageUrl,
                            shortAddress,
                            cuisineTypes,
                            chefImages,
                            latitude,
                            longitude,
                            distanceUnit,
                            description,
                            presetTags,
                          } = chef;
                          return (
                            <RestaurantCard
                              key={`${id}-${slug}`}
                              firstName={name}
                              profileImageUrl={searchResultImageUrl}
                              tagNames={cuisineTypes}
                              mainImageUrl={chefImages[chefIds[0]].mainImageUrl}
                              {...{
                                latitude,
                                longitude,
                                description,
                                distance,
                                presetTags,
                                distanceUnit,
                                shortAddress,
                                slug: `/chefs/${slug}`,
                                openRestaurantLocation,
                              }}
                            />
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              )}

            <h2 className="page-title mt-0 mb-4">Presets</h2>
            {favoritePresets && favoritePresets.length > 0 && (
              <PresetsCategory
                key="Favorites"
                name="Favorites"
                presets={favoritePresets}
                isFavorites
                removeHandler={removeHandler}
                showSortOptions={false}
              />
            )}

            {(!favoritePresets || favoritePresets.length === 0) && (
              <NoFavorites />
            )}

            {savedForLaterPresets && savedForLaterPresets.length > 0 && (
              <PresetsCategory
                key="Saved For Later"
                name="Saved For Later"
                presets={savedForLaterPresets}
                isSavedPresets
                removeHandler={removeHandler}
              />
            )}

            {previouslyOrderedPresets &&
              previouslyOrderedPresets.length > 0 && (
                <PresetsCategory
                  key="Recently Ordered"
                  name="Recently Ordered"
                  presets={previouslyOrderedPresets}
                />
              )}
          </>
        )}
      </div>
    </main>
  );
};

export default Favorites;
