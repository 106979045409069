/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import find from 'lodash/find';
import PropTypes from 'prop-types';
import {
  getPresetItemQuantity,
  getPresetItemTotalPrice,
  getPresetSubTotalPrice,
} from 'utils/preset.util';

import { priceDecimalFormatter } from 'formatter/preset.formatter';
import './CheckoutInfo.css';
import { useSelector } from 'react-redux';
import OrderError from './OrderError';

function CheckoutInfo({
  readOnly,
  showAddondata,
  preset,
  deliveryPriceCents,
  checkoutData,
  setCurrentPresetItemFromCheckoutData,
  removeCheckoutItem,
  preOrderNoticeHour,
  showMinHourNoticeError,
}) {
  const isDelivery = useSelector(state => state.receivingMethod === 'delivery');
  const { presetItems } = preset;
  const subTotal = getPresetSubTotalPrice(checkoutData);

  return (
    <div className="card-body">
      {presetItems && presetItems.length > 0 && (
        <>
          {showMinHourNoticeError && (
            <OrderError
              errorText={`This preset requires ${preOrderNoticeHour} hours notice, please select a delivery date and time that meets the minimum lead time`}
            />
          )}

          {presetItems.map(presetItem => {
            const presetItemCheckoutData = find(
              checkoutData,
              presetForm => presetForm.id === presetItem.id,
            );

            if (presetItemCheckoutData === undefined) return null;
            const {
              menuItems,
              menuItemsMapping,
              specialInstruction,
            } = presetItemCheckoutData;
            return (
              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
              <ul
                className="item-list checkout-item-list"
                key={presetItem.id}
                onClick={() => {
                  setCurrentPresetItemFromCheckoutData(
                    presetItemCheckoutData.id,
                  );
                }}
              >
                <li key={presetItem.id}>
                  <div className="presetItem">
                    {!menuItems.length && (
                      <span className="badge badge-primary badge-pill">
                        {getPresetItemQuantity(presetItemCheckoutData)}
                      </span>
                    )}
                    <span>{presetItem.name}</span>
                  </div>
                  <div>
                    <span className="cost">
                      ${getPresetItemTotalPrice(presetItemCheckoutData) / 100}
                    </span>
                    {!readOnly && (
                      <svg
                        className="icon icon-close"
                        onClick={e => {
                          e.stopPropagation();
                          removeCheckoutItem(presetItem.id);
                        }}
                      >
                        <use xlinkHref="#spriteIcon-close" />
                      </svg>
                    )}
                  </div>
                </li>
                {menuItems.map(menuItem => {
                  if (
                    !menuItemsMapping[menuItem.id] ||
                    parseInt(menuItemsMapping[menuItem.id], 10) === 0
                  ) {
                    return null;
                  }

                  return (
                    <li key={menuItem.id}>
                      <div className="menuItem">
                        <span className="badge badge-primary badge-pill">
                          {parseInt(menuItemsMapping[menuItem.id], 10)}
                        </span>
                        <span>{menuItem.name}</span>
                      </div>
                      <div>
                        <span className="cost">
                          {/* ${(presetItem.quantity * pricePerPersonCents) / 100} */}
                        </span>
                      </div>
                    </li>
                  );
                })}
                {specialInstruction && (
                  <li className="li--instruction">
                    <div className="specialInstruction head">Instructions:</div>
                    <div className="specialInstruction body">
                      {specialInstruction}
                    </div>
                  </li>
                )}
              </ul>
            );
          })}

          <ul className="total">
            <li>
              <span>Subtotal</span>
              <span>${priceDecimalFormatter(subTotal)}</span>
            </li>

            <>
              {!showAddondata && isDelivery && (
                <li>
                  <span>Delivery</span>
                  <span>${priceDecimalFormatter(deliveryPriceCents)}</span>
                </li>
              )}
            </>

            <li>
              <span>
                {preset.taxName || 'HST'} ({(preset.taxRate * 100).toFixed(3)}%)
              </span>
              <span>
                $
                {priceDecimalFormatter(
                  (!showAddondata && isDelivery
                    ? subTotal + deliveryPriceCents
                    : subTotal) * preset.taxRate,
                )}
              </span>
            </li>
          </ul>
        </>
      )}
      {!presetItems ||
        (presetItems.length === 0 && (
          <div className="cart-empty">
            <img src="/assets/img/cart-empty.svg" alt="empty_cart" />
            <p>
              Your cart is empty!
              <br />
              Add items to get started.
            </p>
          </div>
        ))}
    </div>
  );
}

CheckoutInfo.propTypes = {
  preset: PropTypes.shape(),
  checkoutData: PropTypes.arrayOf(PropTypes.shape({})),
  deliveryPriceCents: PropTypes.number,
  setCurrentPresetItemFromCheckoutData: PropTypes.func,
  removeCheckoutItem: PropTypes.func,
  preOrderNoticeHour: PropTypes.number,
  showMinHourNoticeError: PropTypes.bool,
  readOnly: PropTypes.bool,
};

CheckoutInfo.defaultProps = {
  preset: {},
  checkoutData: [],
  deliveryPriceCents: 0,
  preOrderNoticeHour: 0,
  showMinHourNoticeError: false,
  readOnly: false,
  setCurrentPresetItemFromCheckoutData: () => {},
  removeCheckoutItem: () => {},
};
export default CheckoutInfo;
