/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Field, Form, Formik } from 'formik';
import { connect, useDispatch } from 'react-redux';
import * as yup from 'yup';
import classnames from 'classnames';
import { SERVING_STYLE } from 'consts/checkout';
import {
  REQUIRED_VALIDATION,
  REQUIRED_SIGNIN_FOR_PAYMENT,
  INVALID_EMAIL,
  INVALID_INTEGER,
  DEFAULT_TIME_FORMAT,
  DEFAULT_DATE_FORMAT_SAFARI,
} from 'consts/form';
import FormError from 'components/common/FormError';
import DatePickerField from 'components/common/DatePickerField';
import AddressPickerField from 'components/common/AddressPickerField';
import {
  buildCheckoutDataFromPresetItem,
  buildPaymentIntentPresetItems,
} from 'utils/preset.util';
import { useNavigate } from 'react-router-dom';
import {
  useHistory,
  useLocation,
  useParams,
  withRouter,
} from 'react-router-dom';
import LoadingAnimation from 'components/common/LoadingAnimation';
import { setInitialValues } from 'components/common/DeliveryInfoForm';
import { showSignInModal } from 'redux/actions/modalActions';
import {
  addLastMinuteItemPayment,
  cateringOrderUpdate,
  getCateringOrder,
  getLastMinuteAddItem,
} from 'redux/catering/actions';
import { getPresetDetail } from 'api/presetApi';
import { format } from 'date-fns';
import ConfirmModal from 'components/common/ConfirmModal';
import PresetItemView from 'components/common/PresetItemView';
import { store } from 'react-notifications-component';
import { setPreset } from 'redux/restaurants/actions';
import Sticky from 'react-sticky-el';
import OrderDetails from 'components/common/OrderDetails';
import { Button } from 'react-bootstrap';
import { PlaceholderHeader } from 'pages/PresetDetail/Loading';
import { priceDecimalFormatter } from 'formatter/preset.formatter';
import { PRESET_TRUNCATE_THRESHOLD } from 'consts/presets';
import TruncateWrapper from 'components/common/TruncateWrapper';
import RestaurantMenu from 'pages/RestaurantDetails/RestaurantMenu';
import OrderBasket from 'components/common/OrderBasket';
import { createLastMinuteAddItem } from 'api/cateringApi';
import { Link } from 'react-scroll';

const validationSchema = yup.object().shape({
  address: yup.string().required(REQUIRED_VALIDATION),
  date: yup
    .date()
    .typeError(REQUIRED_VALIDATION)
    .required(REQUIRED_VALIDATION)
    .notOneOf([null], REQUIRED_VALIDATION),
  time: yup
    .date()
    .typeError(REQUIRED_VALIDATION)
    .required(REQUIRED_VALIDATION)
    .notOneOf([null], REQUIRED_VALIDATION),
  notes: yup.string(),
  servingStyle: yup
    .mixed()
    .oneOf([SERVING_STYLE.INDIVIDUAL, SERVING_STYLE.FAMILY]),
  cutleryNeeded: yup.boolean(),
  cutleryCount: yup
    .number()
    .min(0, INVALID_INTEGER)
    .nullable()
    .typeError(INVALID_INTEGER),
  firstName: yup.string().required(REQUIRED_VALIDATION),
  lastName: yup.string().required(REQUIRED_VALIDATION),
  companyName: yup.string().required(REQUIRED_VALIDATION),
  phoneNumber: yup.string().required(REQUIRED_VALIDATION),
  emailAddress: yup
    .string()
    .typeError(INVALID_EMAIL)
    .email(INVALID_EMAIL)
    .required(REQUIRED_VALIDATION),
});

function OrderEdit({
  user,
  getCateringOrder,
  getLastMinuteAddItem,
  createLastMinuteAddItem,
  cateringOrderInfo,
  orderItem,
  setPreset,
  cateringOrderUpdate,
  cateringOrderError,
}) {
  const deliveryInfoDefaultValues = setInitialValues();
  const [preset, setPresetLocal] = useState({});
  const [checkoutData, setCheckoutData] = useState([]);
  const [initialCheckout, setInitialCheckout] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [restaurantAddress, setRestaurantAddress] = useState(null);
  const [addressPickerFocused, setAddressPickerFocused] = useState(false);
  const [datePickerFocused, setDatePickerFocused] = useState(false);
  const [timePickerFocused, setTimePickerFocused] = useState(false);
  const [currentPresetItem, setCurrentPresetItem] = useState(null);
  const [removePresetId, setRemovePresetId] = useState(null);
  const [updateChanges, setUpdateChanges] = useState(false);
  const [addItemChanges, setAddItemChanges] = useState(false);
  const [cancelChanges, setCancelChanges] = useState(false);
  const [initialValues, setInitialValuesLocal] = useState({
    servingStyle: SERVING_STYLE.INDIVIDUAL,
    address: deliveryInfoDefaultValues.address,
    address2: '',
    date: deliveryInfoDefaultValues.date,
    time: deliveryInfoDefaultValues.time,
    notes: '',
    cutleryNeeded: false,
    cutleryCount: 0,
    firstName: '',
    lastName: '',
    companyName: '',
    phoneNumber: '',
    emailAddress: '',
  });
  const [changes, setChanges] = useState(false);
  const { order_id: orderId } = useParams(); // I guess will be used later to fetch real Chef data
  const { pathname } = useLocation();
  const history = useHistory();
  const chefSlug = preset?.slug;
  const isSignedIn = user && user.confirmed;
  const AddExtraItem = cateringOrderInfo?.isAddonOrdersEnabled;
  // const AddExtraItem = isAddonOrdersEnabled; //is_addon_orders_enabled; isAddonOrdersEnabled
  useEffect(() => {
    if (!isSignedIn) {
      history.push({
        pathname: '/',
        state: {
          showSignIn: true,
          linkRedirect: pathname,
        },
      });
    }
  });

  useEffect(() => {
    if (cateringOrderInfo?.presetId) {
      const fetchData = async () => {
        const preset = await getPresetDetail(cateringOrderInfo?.presetId);
        setPresetLocal(preset);
        setLoading(false);
      };
      fetchData();

      const dataTime =
        cateringOrderInfo?.orderDateTime &&
        new Date(cateringOrderInfo.orderDateTime);

      setInitialValuesLocal(prev => ({
        ...prev,
        servingStyle:
          cateringOrderInfo?.extraInfo?.servingStyle || prev.servingStyle,
        address: cateringOrderInfo?.deliveryAddress || prev.address,
        address2: cateringOrderInfo?.deliverySuiteAddress || '',
        date: dataTime || prev.date,
        time: dataTime || prev.time,
        notes: cateringOrderInfo?.moreDetail || '',
        cutleryNeeded: !!cateringOrderInfo?.extraInfo?.numCutlery || false,
        cutleryCount: cateringOrderInfo?.extraInfo?.numCutlery || 0,
        firstName: cateringOrderInfo?.firstName || '',
        lastName: cateringOrderInfo?.lastName || '',
        companyName: cateringOrderInfo?.companyName || '',
        phoneNumber: cateringOrderInfo?.phoneNumber || '',
        emailAddress: cateringOrderInfo?.email || '',
      }));
    }
  }, [cateringOrderInfo?.presetId]);

  useEffect(() => {
    if (cateringOrderError && cateringOrderError !== 'Not authorized') {
      history.push('/error');
    }
  }, [cateringOrderError]);

  useEffect(() => {
    if (preset?.id && cateringOrderInfo?.presetId) {
      const { address1, address2, city, state, zip, country } = preset.chefs[0];
      const newArr = [];
      setRestaurantAddress(
        [address1, address2, city, state, zip, country]
          .filter(address => address)
          .join(', '),
      );

      // eslint-disable-next-line no-unused-expressions
      cateringOrderInfo?.purchaseItems?.forEach(item => {
        const repArr = newArr.findIndex(
          item2 => item2.menuId === item.parentId,
        );
        const menu = preset?.chefs?.[0]?.menus?.find(
          item2 => +item2.id === +item.parentId,
        );
        const menuItems = menu?.menuItems;
        const menuItemsMapping = {};
        (menuItems || []).forEach(item2 => {
          menuItemsMapping[item2.id] =
            item.modelId === item2.id
              ? item.amount
              : newArr?.[repArr]?.menuItemsMapping?.[item2.id] || 0;
        });

        if (repArr > -1) {
          newArr[repArr] = {
            ...newArr[repArr],
            menuItemsMapping,
            quantity: +newArr[repArr].quantity + +item.amount,
          };
        } else {
          newArr.push({
            ...menu,
            chefId: cateringOrderInfo?.chefId,
            id: `Additional_${item.parentId}`,
            menuId: item.parentId,
            menuItems,
            menuItemsMapping,
            name: item.modelType === 'MenuItem' ? item.menuName : item.name,
            presetMenuCategoryName: 'Additional Items',
            priceCents: item.itemPrice,
            quantity: item.amount,
            specialInstruction: item.detail,
          });
        }
      });
      setInitialCheckout(newArr);
      setCheckoutData(newArr);
    }
  }, [preset]);

  const setCurrentPresetItemFromCheckoutData = presetItemId => {
    const presetItem = checkoutData.find(
      presetItem => presetItem.id === presetItemId,
    );
    if (presetItem) {
      setCurrentPresetItem(presetItem);
    }
  };

  const removePresetItem = removeItemId => {
    const filteredCheckoutData = checkoutData.filter(
      presetItemData => presetItemData.id !== removeItemId,
    );

    const { presetItems } = preset;
    const filteredPresetItems = presetItems.filter(
      presetItem => presetItem.id !== removeItemId,
    );

    setCheckoutData(filteredCheckoutData);
    setPreset({ ...preset, presetItems: filteredPresetItems });

    store.addNotification({
      title: 'Item removed!',
      message: 'If you want to add it back, please check additional items.',
      type: 'danger',
      insert: 'top',
      container: 'top-right',
      animationIn: ['animated', 'bounceIn'],
      animationOut: ['animated', 'fadeOut'],
      dismiss: {
        duration: 4000,
      },
    });
  };

  const updateCheckoutData = (newPresetItem, newPresetItemData) => {
    const newCheckoutData = checkoutData.map(item => {
      if (item.id === newPresetItem.id) {
        return { ...newPresetItem, ...newPresetItemData };
      }

      return item;
    });
    setCheckoutData(newCheckoutData);
    setPreset({
      ...preset,
      presetItems: newCheckoutData,
    });

    const foundItem = checkoutData.find(item => item.id === newPresetItem.id);

    if (!foundItem) {
      setPreset({
        ...preset,
        presetItems: [
          ...checkoutData,
          { ...newPresetItem, ...newPresetItemData },
        ],
      });
      setCheckoutData(prev => [
        ...prev,
        { ...newPresetItem, ...newPresetItemData },
      ]);

      store.addNotification({
        title: 'Item added!',
        message: 'Please check the additional items category.',
        type: 'success',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animated', 'bounceIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 4000,
        },
      });
    }
  };

  const getCurrentPresetItemChefInfo = useMemo(() => {
    if (!(currentPresetItem && preset?.chefs?.length)) {
      return null;
    }

    const currentChef = preset?.chefs?.find(
      chef => chef.id === currentPresetItem.chefId,
    );

    const menu = currentChef?.menus?.find(
      menu => menu.id === currentPresetItem.menuId,
    );

    return {
      chefImage: currentChef.profileImageUrl,
      menuType: menu.menuType,
      minOrderAmount: menu.minOrderAmount,
    };
  }, [currentPresetItem]);

  useEffect(() => {
    if (orderId) {
      setLoading(true);
      getCateringOrder(+orderId);
    }
  }, [orderId]);

  const handleUpdate = () => setUpdateChanges(true);
  const handleCancel = () => setCancelChanges(true);

  const updateOrder = async (checkoutData, preset, form) => {
    try {
      setLoading(true);

      const {
        firstName,
        lastName,
        companyName,
        phoneNumber,
        emailAddress,
        cutleryNeeded,
        cutleryCount,
        servingStyle,
      } = form;
      const cart = {
        presetId: preset?.id,
        presetName: preset?.name,
        numCutlery: cutleryNeeded ? parseInt(cutleryCount, 10) : 0,
        firstName,
        lastName,
        companyName,
        phoneNumber,
        email: emailAddress,
        servingStyle,
        presetItems: buildPaymentIntentPresetItems(checkoutData),
        receivingMethod: cateringOrderInfo?.orderType,
      };

      const dataTime =
        form && form.date && form.time
          ? new Date(
              `${format(form.date, DEFAULT_DATE_FORMAT_SAFARI)} ${format(
                form.time,
                DEFAULT_TIME_FORMAT,
              )} ${preset?.presetLocation?.currentTime?.slice(-6)}`,
            )
          : '';
      if (cateringOrderInfo?.orderType === 'delivery') {
        cart.deliveryAddress = form ? form.address : '';
        cart.deliverySuiteAddress = form ? form.address2 : '';
        cart.deliveryDate = dataTime;
        cart.deliveryTime = dataTime;
        cart.deliveryInstruction = form ? form.notes : '';
      } else {
        cart.pickupDate = dataTime;
        cart.pickupTime = dataTime;
        cart.pickupInstruction = form ? form.notes : '';
      }

      // eslint-disable-next-line react/prop-types
      const isEft = cateringOrderInfo?.purchasedWith === 'acss_debit';

      await cateringOrderUpdate(cart, orderId, isEft);
      await history.push('/checkout-completed');
    } catch (e) {
      // alert(e.message)
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async form => {
    if (!isSignedIn) {
      showSignInModal(REQUIRED_SIGNIN_FOR_PAYMENT);
      return;
    }
    await updateOrder(checkoutData, preset, form);
  };

  return (
    <main>
      {isLoading && <LoadingAnimation />}
      {cateringOrderInfo?.id && !isLoading ? (
        <div className="container checkout py-5 mb-5">
          <Formik
            {...{ initialValues, validationSchema }}
            validateOnChange
            validateOnBlur
            onSubmit={onSubmit}
          >
            {({
              setFieldValue,
              values,
              touched,
              handleBlur,
              setFieldTouched,
              resetForm,
              submitForm,
            }) => (
              <Form onChange={() => setChanges(true)} className="row">
                <div className="col-xl-8 pr-xl-5">
                  {(isLoading || !preset?.id) && <PlaceholderHeader />}

                  {!isLoading && preset?.id && (
                    <>
                      <div className="detailed-view--cover hide-mobile">
                        <img
                          className="img-fluid"
                          src={preset.bannerImageUrl}
                          alt="banner_img"
                        />

                        <ul className="restaurants large">
                          {preset?.chefs &&
                            preset?.chefs.map(chef => (
                              <li key={chef.id}>
                                <img
                                  className="img-fluid"
                                  src={chef.profileImageUrl}
                                  alt="chef_logo"
                                />
                              </li>
                            ))}
                        </ul>
                      </div>

                      <div className="detailed-view--header">
                        <div>
                          <h2 className="mb-2">{preset.name}</h2>
                          <div className="star-ratings-sprite-wrapper">
                            <div className="star-ratings-sprite star-ratings-sprite--green">
                              <span
                                style={{
                                  width: `${preset.averageRating * 20}%`,
                                }}
                                className="star-ratings-sprite-rating star-ratings-sprite-rating--green"
                              />
                            </div>

                            <span className="star-ratings-label">
                              {preset.averageRating}
                            </span>
                          </div>

                          <p style={{ fontSize: '14px' }}>
                            <svg className="icon icon-dinner">
                              <use xlinkHref="#spriteIcon-dinner" />
                            </svg>
                            Ordered {preset.orderCount} times
                          </p>
                        </div>
                        <div className="buttons">
                          <button
                            className="btn btn-outline-primary btn-block hide-desktop"
                            data-toggle="dynamic-modal"
                            data-target="#modalOrderDetails"
                            type="button"
                            disabled={!changes}
                            onClick={() => handleUpdate()}
                          >
                            Update Order
                          </button>

                          <button
                            className="btn btn-outline-primary btn-block hide-desktop"
                            data-toggle="dynamic-modal"
                            data-target="#modalOrderDetails"
                            type="button"
                            disabled={!changes}
                            onClick={() => handleCancel()}
                          >
                            Cancel Changes
                          </button>
                        </div>
                      </div>

                      {cateringOrderInfo?.orderType === 'delivery' && (
                        <div className="detailed-view--info mb-4">
                          <div className="entry">
                            <div className="title">Delivery Fee</div>
                            <div className="value">
                              $
                              {priceDecimalFormatter(preset.deliveryPriceCents)}
                            </div>
                          </div>
                          <div className="delimiter" />
                          <div className="entry">
                            <div className="title">This Vendor Requires</div>
                            <div className="value">
                              {preset.preOrderNoticeHour} Hours Notice
                            </div>
                          </div>
                          <div className="delimiter" />
                          <div className="entry">
                            <div className="title">Minimum Order Amount</div>
                            <div className="value">
                              $
                              {priceDecimalFormatter(
                                preset.minimumOrderAmountCents,
                              )}
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="mb-5">
                        <TruncateWrapper
                          text={preset.description}
                          threshold={PRESET_TRUNCATE_THRESHOLD}
                        />
                      </div>
                    </>
                  )}
                  <h2>Edit Delivery Details</h2>
                  <h3 className="mt-4 mb-3">Your details</h3>
                  <div className="row">
                    <div className="col-xl-6">
                      <div className="labeled-group floating">
                        <Field
                          type="input"
                          className="form-control"
                          name="firstName"
                        />
                        <FormError name="firstName" />
                        <label
                          htmlFor="firstName"
                          className={classnames({
                            'label-focused': values.firstName,
                          })}
                        >
                          First Name
                        </label>
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="labeled-group floating">
                        <Field
                          type="input"
                          className="form-control"
                          name="lastName"
                        />
                        <FormError name="lastName" />
                        <label
                          htmlFor="lastName"
                          className={classnames({
                            'label-focused': values.lastName,
                          })}
                        >
                          Last Name
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-6">
                      <div className="labeled-group floating">
                        <Field
                          type="input"
                          className="form-control"
                          name="companyName"
                        />
                        <FormError name="companyName" />
                        <label
                          htmlFor="companyName"
                          className={classnames({
                            'label-focused': values.companyName,
                          })}
                        >
                          Company Name
                        </label>
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="labeled-group floating">
                        <Field
                          type="input"
                          className="form-control"
                          name="phoneNumber"
                        />
                        <FormError name="phoneNumber" />
                        <label
                          htmlFor="phoneNumber"
                          className={classnames({
                            'label-focused': values.phoneNumber,
                          })}
                        >
                          Phone Number
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-6">
                      <div className="labeled-group floating">
                        <Field
                          className="form-control"
                          name="emailAddress"
                          type="email"
                        />
                        <FormError name="emailAddress" />
                        <label
                          htmlFor="emailAddress"
                          className={classnames({
                            'label-focused': values.emailAddress,
                          })}
                        >
                          Email
                        </label>
                      </div>
                    </div>
                  </div>

                  {cateringOrderInfo?.orderType === 'delivery' && (
                    <>
                      <h3 className="mt-5 mb-3">Delivery details</h3>
                      <div className="row">
                        <div className="col-xl-8">
                          <div className="labeled-group floating">
                            <AddressPickerField
                              id="checkout-address"
                              name="address"
                              value={values.address}
                              defaultAddress={
                                cateringOrderInfo?.deliveryAddress
                              }
                              onChange={(name, address) => {
                                setChanges(true);
                                setFieldValue(name, address);
                              }}
                              onFocus={() => {
                                setAddressPickerFocused(true);
                                setFieldTouched('address');
                              }}
                              onBlur={e => {
                                setAddressPickerFocused(false);
                                handleBlur(e);
                              }}
                            />
                            <label
                              htmlFor="checkout-address"
                              className={classnames({
                                'label-focused':
                                  addressPickerFocused || values.address,
                              })}
                            >
                              Delivery Address
                            </label>
                            <FormError name="address" />
                          </div>
                        </div>
                        <div className="col-xl-4">
                          <div className="labeled-group floating">
                            <Field
                              type="input"
                              className="form-control"
                              name="address2"
                            />
                            <FormError name="address2" />
                            <label
                              htmlFor="lastName"
                              className={classnames({
                                'label-focused': values.address2,
                              })}
                            >
                              Suite #
                            </label>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  {cateringOrderInfo?.orderType === 'pickup' && (
                    <>
                      <h3 className="mt-5 mb-3">Pick up details</h3>
                      <div className="labeled-group floating">
                        <input
                          type="text"
                          readOnly
                          className="form-control form-control-plaintext"
                          value={restaurantAddress}
                        />
                        <label
                          htmlFor="checkout-address"
                          className={classnames({
                            'label-focused': true,
                          })}
                        >
                          Pick up Address
                        </label>
                        <FormError name="address" />
                      </div>
                    </>
                  )}

                  <div className="row">
                    <div className="col-xl-6">
                      <div className="labeled-group floating">
                        <div className="input-with-icons mb-2 ii-right">
                          <DatePickerField
                            id="checkout-date"
                            name="date"
                            value={values.date}
                            onChange={(name, val) => {
                              setChanges(true);
                              setFieldValue(name, val);
                            }}
                            onFocus={() => {
                              setDatePickerFocused(true);
                              setFieldTouched('date');
                            }}
                            onBlur={e => {
                              setDatePickerFocused(false);
                              handleBlur(e);
                            }}
                          />
                          <FormError name="date" />
                          <label
                            htmlFor="checkout-date"
                            className={classnames({
                              'label-focused': datePickerFocused || values.date,
                            })}
                          >
                            {cateringOrderInfo?.orderType === 'pickup'
                              ? 'Pick up Date'
                              : 'Delivery Date'}
                          </label>
                          <div className="input-append">
                            <svg className="icon icon-calender">
                              <use xlinkHref="#spriteIcon-calender" />
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="labeled-group floating">
                        <div className="input-with-icons mb-2 ii-right">
                          <DatePickerField
                            id="checkout-time"
                            name="time"
                            showTimeSelect
                            showTimeSelectOnly
                            value={values.time}
                            onChange={(name, val) => {
                              setChanges(true);
                              setFieldValue(name, val);
                            }}
                            onFocus={() => {
                              setTimePickerFocused(true);
                              setFieldTouched('time');
                            }}
                            onBlur={e => {
                              setTimePickerFocused(false);
                              handleBlur(e);
                            }}
                          />
                          <FormError name="time" />
                          {preset?.presetLocation?.timeZone && (
                            <div className="labeled-group__danger">
                              This is based on{' '}
                              {preset?.presetLocation?.timeZone}
                            </div>
                          )}
                          <label
                            htmlFor="checkout-time"
                            className={classnames({
                              'label-focused': timePickerFocused || values.time,
                            })}
                          >
                            {cateringOrderInfo?.orderType === 'pickup'
                              ? 'Pick up Time'
                              : 'Delivery Time'}
                          </label>
                          <div className="input-append">
                            <svg className="icon icon-clock">
                              <use xlinkHref="#spriteIcon-clock" />
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="labeled-group floating">
                    <Field
                      component="textarea"
                      className="form-control"
                      name="notes"
                      id="checkout-notes"
                      rows={2}
                    />
                    <FormError name="notes" />
                    <label
                      htmlFor="checkout-notes"
                      className={classnames({
                        'label-focused': touched.notes || values.notes,
                      })}
                    >
                      Additional{' '}
                      {cateringOrderInfo?.orderType === 'pickup'
                        ? 'pick up '
                        : 'delivery '}
                      instructions
                    </label>
                  </div>
                  <FormError name="cutleryCount" />
                  <div className="checkout--options-fields mb-xl-5">
                    <div className="left">
                      <div className="custom-control custom-radio custom-control-inline">
                        <Field
                          className="custom-control-input"
                          id="individually"
                          type="radio"
                          name="servingStyle"
                          value={SERVING_STYLE.INDIVIDUAL}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="individually"
                        >
                          Serve Individually Packaged
                        </label>
                      </div>
                      <div className="custom-control custom-radio custom-control-inline">
                        <Field
                          className="custom-control-input"
                          id="family"
                          type="radio"
                          name="servingStyle"
                          value={SERVING_STYLE.FAMILY}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="family"
                        >
                          Serve Family Style
                        </label>
                      </div>
                    </div>
                    <div className="right">
                      <div className="custom-control custom-checkbox">
                        <Field
                          className="custom-control-input"
                          id="checkout-cutlery"
                          type="checkbox"
                          name="cutleryNeeded"
                          checked={values.cutleryNeeded}
                          onChange={e => {
                            const value = e.target.checked;
                            setFieldValue('cutleryNeeded', value);
                          }}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="checkout-cutlery"
                        >
                          <span>I need cutlery</span>
                        </label>
                      </div>
                      <div className="ml-4">
                        <div className="labeled-group" style={{ width: 80 }}>
                          <Field
                            type="input"
                            className="form-control"
                            name="cutleryCount"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {preset?.id && preset?.chefs && preset?.chefs.length > 0 && (
                    <div className="restaurant-menu_wrapper">
                      <RestaurantMenu
                        {...{
                          chefs: preset?.chefs,
                          presetItems: checkoutData,
                          setCurrentPresetItem,
                          checkoutData,
                        }}
                      />
                    </div>
                  )}
                </div>
                <div className="col-xl-4 pl-xl-2" style={{ zIndex: 2 }}>
                  <Sticky
                    stickyClassName="mt-20"
                    boundaryElement=".sticky-container"
                    hideOnBoundaryHit={false}
                  >
                    <div className="mb-4">
                      <OrderDetails
                        preset={{
                          ...preset,
                          presetItems: checkoutData,
                        }}
                        typeDetails={false}
                        buttons={
                          <>
                            {/* {AddExtraItem ? (
                              <Button
                                className="btn btn-primary btn-block mt-3"
                                type="button"
                                onClick={handleAddItem}
                              >
                                Add Last Minute Order
                              </Button>
                            ) : null} */}
                            <Button
                              className="btn btn-primary btn-block mt-3"
                              type="button"
                              disabled={!changes}
                              onClick={() => handleUpdate()}
                            >
                              Update Order
                            </Button>
                            <button
                              className="btn btn-outline-primary btn-block"
                              data-toggle="dynamic-modal"
                              data-target="#modalOrderDetails"
                              type="button"
                              disabled={!changes}
                              onClick={() => handleCancel()}
                            >
                              Cancel Changes
                            </button>
                          </>
                        }
                        deliveryPriceCents={cateringOrderInfo?.deliveryFee}
                        checkoutData={checkoutData}
                        removeCheckoutItem={checkoutItemId => {
                          setRemovePresetId(checkoutItemId);
                        }}
                        setCurrentPresetItemFromCheckoutData={
                          setCurrentPresetItemFromCheckoutData
                        }
                      />
                    </div>
                  </Sticky>
                </div>
                {cancelChanges && (
                  <ConfirmModal
                    show
                    description="Are you sure that you want to cancel changes? All changes made to the order will be reverted back to the original order."
                    confirmText="Yes"
                    cancelText="No"
                    onConfirm={() => {
                      resetForm();
                      setCheckoutData(initialCheckout);
                      setCancelChanges(false);
                      setChanges(false);
                    }}
                    onClose={() => {
                      setCancelChanges(false);
                    }}
                  />
                )}
                {updateChanges && (
                  <ConfirmModal
                    show
                    description="Are you sure that you want to submit this updated order? Once the order has been submitted it will be sent to the vendor."
                    confirmText="Yes"
                    cancelText="No"
                    onConfirm={() => {
                      submitForm();
                      setUpdateChanges(false);
                      setChanges(false);
                    }}
                    onClose={() => {
                      setUpdateChanges(false);
                    }}
                  />
                )}
              </Form>
            )}
          </Formik>

          {removePresetId && (
            <ConfirmModal
              show
              description="Are you sure you want to remove this item from your order?"
              confirmText="Remove"
              onConfirm={() => {
                removePresetItem(removePresetId);
                setRemovePresetId(null);
              }}
              onClose={() => {
                setRemovePresetId(null);
              }}
            />
          )}
          {currentPresetItem && (
            <PresetItemView
              presetItem={currentPresetItem}
              initialValues={
                checkoutData.find(
                  presetForm => presetForm.id === currentPresetItem.id,
                ) || buildCheckoutDataFromPresetItem(currentPresetItem)
              }
              chefInfo={getCurrentPresetItemChefInfo}
              onClose={() => {
                setCurrentPresetItem(null);
              }}
              onSubmit={(presetItem, form) => {
                setChanges(true);
                updateCheckoutData(presetItem, form);
              }}
            />
          )}
        </div>
      ) : null}
      <section className="total-footer hide-desktop">
        <OrderBasket
          buttonWrap={
            <>
              <button
                className="btn btn-primary mr-3"
                type="button"
                disabled={!changes}
                onClick={e => {
                  e.stopPropagation();
                  handleUpdate();
                }}
              >
                Update
              </button>
              <button
                className="btn btn-primary"
                type="button"
                disabled={!changes}
                onClick={e => {
                  e.stopPropagation();
                  handleCancel();
                }}
              >
                Cancel
              </button>
            </>
          }
          totalItems={checkoutData?.length}
          preset={{
            ...preset,
            presetItems: checkoutData,
          }}
          receivingMethod={cateringOrderInfo?.orderType}
          deliveryPriceCents={cateringOrderInfo?.deliveryFee}
          checkoutData={checkoutData}
          removeCheckoutItem={checkoutItemId => {
            setRemovePresetId(checkoutItemId);
          }}
          setCurrentPresetItemFromCheckoutData={
            setCurrentPresetItemFromCheckoutData
          }
        />
        {console.log(checkoutData)}
      </section>
    </main>
  );
}

const mapStateToProps = state => ({
  user: state.user && state.user.user,
  cateringOrderInfo: state.caterings.cateringOrderInfo,
  cateringOrderError: state.caterings.error,
});
const mapDispatchToProps = {
  getCateringOrder,
  getLastMinuteAddItem,
  setPreset,
  cateringOrderUpdate,
};

OrderEdit.propTypes = {
  cateringOrderInfo: PropTypes.shape({
    presetId: PropTypes.number,
    deliveryFee: PropTypes.number,
    totalPrice: PropTypes.number,
    id: PropTypes.number,
    orderType: PropTypes.string,
    deliveryAddress: PropTypes.string,
    deliverySuiteAddress: PropTypes.string,
    orderDateTime: PropTypes.string,
    moreDetail: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    companyName: PropTypes.string,
    phoneNumber: PropTypes.string,
    email: PropTypes.string,
    extraInfo: PropTypes.arrayOf({
      servingStyle: PropTypes.string,
      numCutlery: PropTypes.number,
    }),
    purchaseItems: PropTypes.arrayOf({
      menuName: PropTypes.string,
      menuPrice: PropTypes.number,
      quantity: PropTypes.number,
    }),
    chefId: PropTypes.number,
  }).isRequired,
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    companyName: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    confirmed: PropTypes.bool,
    address2: PropTypes.string,
    defaultDeliveryInstructions: PropTypes.string,
  }).isRequired,
  getCateringOrder: PropTypes.func.isRequired,
  createLastMinuteAddItem: PropTypes.func.isRequired,
  // cateringOrderError: PropTypes.string.isRequired,

  cateringOrderError: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf([null]),
  ]),
  setPreset: PropTypes.func.isRequired,
  cateringOrderUpdate: PropTypes.func.isRequired,
};

const connectedHeader = connect(mapStateToProps, mapDispatchToProps)(OrderEdit);
export default withRouter(connectedHeader);
