// restaurants
export const RESTAURANTS_FETCH_REQUESTED = 'restaurants/FETCH_REQUESTED';
export const RESTAURANTS_FETCH_SUCCESS = 'restaurants/FETCH_SUCCESS';
export const RESTAURANTS_FETCH_FAILED = 'restaurants/FETCH_FAILED';
export const TOGGLE_FILTER_TAG = 'restaurants/TOGGLE_FILTER_TAG';
export const SET_SELECTED_TAGS = 'restaurants/SET_SELECTED_TAGS';

// chef detail page
export const RESTAURANT_DETAIL_FETCH_REQUESTED =
  'restaurants/RESTAURANT_DETAIL_FETCH_REQUESTED';
export const RESTAURANT_DETAIL_FETCH_SUCCESS =
  'restaurants/RESTAURANT_DETAIL_FETCH_SUCCESS';
export const RESTAURANT_DETAIL_FETCH_FOR_ADD_ON_SUCCESS =
  'restaurants/RESTAURANT_DETAIL_FETCH_FOR_ADD_ON_SUCCESS';
export const RESTAURANT_DETAIL_FETCH_FAILED =
  'restaurants/RESTAURANT_DETAIL_FETCH_FAILED';
export const SET_CHECKOUT_DATA = 'restaurants/SET_CHECKOUT_DATA';
export const SET_PRESET = 'restaurants/SET_PRESET';
export const RESET_CHECKOUT_DATA = 'restaurants/RESET_CHECKOUT_DATA';

export const SAVE_CUSTOM_CHEF_PRESET_REQUESTED =
  'restaurants/SAVE_CUSTOM_CHEF_PRESET_REQUESTED';
export const SAVE_CUSTOM_CHEF_PRESET_SUCCEEDED =
  'restaurants/SAVE_CUSTOM_CHEF_PRESET_SUCCEEDED';
export const SAVE_CUSTOM_CHEF_PRESET_FAILED =
  'restaurants/SAVE_CUSTOM_CHEF_PRESET_FAILED';
export const TOGGLE_FAVORITE_STATUS_REQUESTED =
  'restaurants/TOGGLE_FAVORITE_STATUS_REQUESTED';
export const TOGGLE_FAVORITE_STATUS_SUCCEEDED =
  'restaurants/TOGGLE_FAVORITE_STATUS_SUCCEEDED';
export const TOGGLE_FAVORITE_STATUS_FAILED =
  'restaurants/TOGGLE_FAVORITE_STATUS_FAILED';
