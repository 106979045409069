import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  PaymentElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import {
  onCreatePaymentMethod,
  onCreateSetupIntent,
} from 'redux/actions/paymentActions';
import { store } from 'react-notifications-component';
import LoadingAnimation from 'components/common/LoadingAnimation';
import '../../style.scss';

const EFTCard = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const dispatch = useDispatch();
  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
  );
  const paymentIntent = useSelector(state => state.paymentIntent);
  const stripeOptions = {
    clientSecret: paymentIntent?.clientSecret,
    fields: {
      billingDetails: {
        name: 'never',
        email: 'never',
      },
    },
  };
  useEffect(() => {
    dispatch(onCreateSetupIntent());
  }, [dispatch]);
  if (paymentIntent?.clientSecret) {
    return (
      <Elements options={stripeOptions} stripe={stripePromise}>
        <div className="row eft-modal">
          <div className="col-xl-12">
            <div className="labeled-group floating">
              <input
                id="Field-nameInput"
                className="form-control"
                type="text"
                name="full_name"
                required="required"
                onChange={({ target }) => setName(target.value)}
                value={name}
              />
              <label>Full Name</label>
            </div>
          </div>
          <div className="col-xl-12">
            <div className="labeled-group floating">
              <input
                id="Field-emailInput"
                className="form-control"
                type="email"
                name="email"
                required="required"
                onChange={({ target }) => setEmail(target.value)}
                value={email}
              />
              <label>Email</label>
            </div>
          </div>
        </div>
        <PaymentElement options={stripeOptions} />
        <AddEftCard email={email} name={name} />
      </Elements>
    );
  }
  return null;
};

const AddEftCard = ({ email, name }) => {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const [submitting, setSubmitting] = useState(false);
  const paymentIntent = useSelector(state => state.paymentIntent);
  const handleSubmit = async () => {
    try {
      if (!stripe || !elements) {
        return;
      }
      setSubmitting(true);
      const clientSecret = paymentIntent?.clientSecret;
      const { setupIntent, error } = await stripe.confirmAcssDebitSetup(
        clientSecret,
        {
          payment_method: {
            billing_details: {
              name,
              email,
            },
          },
        },
      );

      if (error) {
        store.addNotification({
          title: 'Card Addition Error!',
          message: error.message,
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animated', 'bounceIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 8000,
          },
        });
        return;
      }
      if (setupIntent.status === 'succeeded') {
        // eslint-disable-next-line camelcase
        const { payment_method, id } = setupIntent || {};
        dispatch(
          onCreatePaymentMethod({
            paymentMethodId: payment_method,
            StripeSetupIntentId: id,
            name,
            email,
          }),
        );
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      {submitting ? <LoadingAnimation /> : null}
      {/* eslint-disable-next-line react/button-has-type */}
      <button
        onClick={handleSubmit}
        disabled={submitting || !stripe}
        className="btn btn-block btn-primary"
      >
        Confirm and Continue Saving Bank Account
      </button>
    </>
  );
};
export default EFTCard;
